    <div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
        <div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
            <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-center justify-between">
                    <p class="text-[24px] font-semibold text-primaryBlack">Book asset</p>
                    <button class="flex size-[30px] rounded-md bg-primaryBlack items-center justify-center 
                                hover:bg-red transition-[0.5s]" style="outline:none" (click)="modalService.close()">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                    </button>
                </div>
                @if (selectedAsset.Permission) {
                <div class="flex flex-row w-full items-center p-4 gap-4 rounded-md bg-lightYellow/30">
                    <img src="../../../../assets/icons/SupportIcon.png" class="size-[25px]" />
                    <div class="flex flex-col w-full">
                        <p class="text-primaryBlack text-[14px] font-medium">THIS ASSET REQUIRES PERMISSION</p>
                        <p class="text-primaryBlack text-[12px] font-light">
                            In order to use this asset, you must obtain permission from a superior.
                            When you submit an asset booking request, an email will be sent to your supervisor.
                            You can borrow the asset after the superior approves the request.
                        </p>
                    </div>
                </div>
                }
                <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
                    <p class="text-primaryBlack/50 tracking-wide text-[12px] font-medium mb-2">ASSET DETAILS</p>
                    <div class="flex flex-row items-center w-full gap-4">
                        <!-- <div class="flex size-[100px] img-container rounded-md border-[1px] border-primaryBlack/20">
                        @if (selectedAsset?.ImageURL) {
                            <img [src]="getAssetImage(activeAsset?.ImageURL)" class="size-full"/>
                        }
                    </div> -->
                        @if (hasPicture) {
                        <img [src]="assetPictureLink" class="rounded-sm size-full" />
                        } @else {
                        <img src="../../../assets/icons/AssetsIcon.png" class="rounded-sm" />
                        }
                        <div class="flex flex-col w-full gap-2">
                            <p class="font-semibold text-primaryBlack text-[24px]">{{selectedAsset.Name}}</p>
                            <p class="font-light text-primaryBlack text-[16px]">Serial number: <span
                                    class="font-medium">{{selectedAsset.SerialNumber}}</span></p>
                            @if (selectedAsset.Permission) {
                            <p class="font-light text-primaryBlack text-[16px]">Require approval: <span
                                    class="font-medium text-lightRed">YES</span></p>
                            } @else {
                            <p class="font-light text-primaryBlack text-[16px]">Require approval: <span
                                    class="font-medium text-lightGreen">NO</span></p>
                            }
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-full border-[1px] rounded-md p-4">
                    <div class="flex flex-col w-full">
                        <p class="text-primaryBlack/50 tracking-wide text-[12px] font-medium mb-2">ENGAGEMENTS</p>
                        <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following projects that
                            you
                            work on</p>
                    </div>
                    <div
                        class="flex w-full max-h-[200px] flex-row flex-wrap justify-begin gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                        @for (engagement of engagements; track $index) {
                            <div
                            class="flex flex-row justify-begin px-4 py-2 gap-2 rounded-md relative transition-[0.5s] cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto"
                            [ngClass]="{'border-[2px]': isActive(engagement.ProjectID), 'border-[1px]': !isActive(engagement.ProjectID)}"
                            [ngStyle]="{
                              'background-color': isActive(engagement.ProjectID) ? 'var(--theme-color)' : 'transparent',
                              'border-color': isActive(engagement.ProjectID) ? 'var(--theme-color)' : 'var(--inactive-border-color)',
                              'hover': 'var(--hover-color, #b2d8b2)'
                            }"
                            (click)="setActive(engagement.ProjectID)"
                          >
                            <div class="flex flex-col">
                              <p class="text-[14px]" [style.color]="isActive(engagement.ProjectID) ? 'white' : 'black'">{{engagement.ProjectName}}</p>
                            </div>
                          </div>
                        }
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    <label for="email" class="text-primaryBlack font-regular text-[12px]">Return date</label>
                    <input [(ngModel)]="endDate" type="date" id="dateInput" [min]="minDate"
                        class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack"
                        [ngStyle]="{'outline-color': 'var(--theme-color)', 'outline-width': '2px'}">
                </div>

                @if (!selectedAsset.Permission) {

                <div class="flex flex-row items-center w-full justify-between">
                    <div class="flex flex-col items-start h-auto">
                        <label for="bookCount" class="text-primaryBlack font-regular text-[12px] mr-2" style="white-space: nowrap; overflow: hidden;">
                            Select amount
                        </label>
                        <input [(ngModel)]="bookCount" type="number" id="bookCount" min=1
                            class="w-20 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-2 text-primaryBlack"
                            [ngStyle]="{'outline-color': 'var(--theme-color)', 'outline-width': '2px'}">
                    </div>
                    <div class="flex flex-row items-center w-full justify-end">
                        <button
                            class="flex h-[45px] rent-asset-button font-medium font-poppins rounded-md relative text-[18px] transition-[0.5s] items-center justify-center p-4 gap-2 border-slate-50"
                            [disabled]="!endDate || bookCount<1" (click)="bookAsset()">
                            <div class="flex justify-end items-center">Book asset</div>
                        </button>
                    </div>
                </div>
                } @else {
                <div class="flex flex-row items-center w-full justify-end">
                    <button
                        class="flex h-[45px] rent-asset-button font-medium font-poppins rounded-md relative text-[18px] transition-[0.5s] items-center justify-center p-4 gap-2 border-slate-50"
                        [disabled]="!endDate" (click)="bookAsset()">
                        <div class="flex justify-end items-center ">Request asset</div>
                    </button>
                    
                </div>
                }
            </div>

        </div>
    </div>