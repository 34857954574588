<div class="flex flex-col gap-2 p-4 max-w-[400px] border-[1px] rounded-md custom-border transition-[0.5s]">
    <div class="flex flex-row gap-2 items-center w-full">
        <div class="flex size-[80px] img-container rounded-sm border-[1px] border-primaryBlack/20">
            @if (hasPicture) {
                <img [src]="imageUrl" class="rounded-sm size-full"/>
            } @else {
                <img src="../../../assets/icons/AssetsIcon.png" class="rounded-sm"/>
            }
        </div>
        <div class="flex flex-col gap-2">
            <p class="text-primaryBlack font-medium text-[16px]">{{name}}</p>
            <div class="flex flex-col">
                <!-- <p class="text-primaryBlack font-light text-[14px]">Serial number: <span class="font-semibold">{{assetHist.}}</span></p> -->
                <p class="text-primaryBlack font-light text-[14px]">Start date: <span class="font-semibold">{{getDate(startDate)}}</span></p>
                @if (endDate === -1) {
                    <p class="text-primaryBlack font-light text-[14px]">Returned: <span class="font-semibold text-lightRed">NO</span></p>
                }
                @else {
                    <p class="text-primaryBlack font-light text-[14px]">Returned: <span class="font-semibold text-lightGreen">YES</span></p>
                    <p class="text-primaryBlack font-light text-[14px]">End date: <span class="font-semibold">{{getDate(endDate)}}</span></p>
                }
            </div>
        </div>
    </div>
    @if (endDate === -1) {
        <div class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium border-[1px] 
                    regular-button transition-[0.5s] uppercase cursor-pointer" (click)="function()">
            Return asset
        </div>
    } @else {
        <div class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium uppercase cursor-pointer">
        </div>
    }
</div>