@if (checkedIn) {
    <div class="flex flex-row w-full rounded-lg items-center justify-between">
        <div class="flex flex-row w-full items-center justify-between">
            @if (!activityExists) {
                <button class="flex flex-row w-[220px] rounded-md bg-darkGreen hover:bg-lightHoverGreen transition-[0.5s] px-4 py-2 items-center gap-2 justify-center"
                        (click)="startActivity()">
                    <img src="../assets/icons/ActiveIcon.png" class="size-[25px] nav_menu_icon"/>
                    <p class="text-primaryWhite font-medium">Start activity</p>
                </button>
            } @else {
                <div class="flex flex-row w-full items-center">
                    <div class="flex flex-row w-full gap-4 justify-start items-center">
                        <div class="flex flex-col">
                            <p class="text-[14px] font-medium text-primaryBlack">ONGOING ACTIVITY</p>
                            <p class="text-[12px] font-light text-primaryBlack">{{activeLog?.Title}}</p>
                        </div>
                        <div class="flex flex-row gap-2">
                            <button class="flex flex-row justify-center w-[200px] items-center gap-2 group cursor-pointer transition-[0.5s] mt-2 p-2 bg-lightHoverGreen/25 hover:bg-lightHoverGreen/50 rounded-md"
                                    (click)="endActivity()">
                                <img src="../../../assets/icons/EndActivityIcon.png" class="size-[16px] icon_filter_green"/>
                                <p class="text-[14px] font-medium text-darkGreen">End activity</p>
                            </button>
                            @if (!activityPaused) {
                                <button class="flex flex-row justify-center w-[200px] items-center gap-2 group cursor-pointer transition-[0.5s] mt-2 p-2 bg-lightYellow/25 hover:bg-lightYellow/50 rounded-md"
                                        (click)="pauseActivity()">
                                    <img src="../../../assets/icons/PauseActivityIcon.png" class="size-[16px]"/>
                                    <p class="text-[14px] font-medium text-primaryBlack">Pause activity</p>
                                </button>
                            } @else {
                                <button class="flex flex-row justify-center w-[200px] items-center gap-2 group cursor-pointer transition-[0.5s] mt-2 p-2 bg-pausedBg/35 hover:bg-pausedBg/50 rounded-md"
                                        (click)="resumeActivity()">
                                    <img src="../../../assets/icons/ResumeActivityIcon.png" class="size-[20px] icon_filter_blue"/>
                                    <p class="text-[14px] font-medium text-pausedFg">Resume activity</p>
                                </button>
                            }
                        </div>
                    </div>
                    <!-- <button class="flex flex-row w-[220px] rounded-md bg-darkGreen hover:bg-lightHoverGreen transition-[0.5s] px-4 py-2 items-center gap-2 justify-center"
                            (click)="checkOut()">
                        <img src="../assets/icons/ActiveIcon.png" class="size-[25px] nav_menu_icon"/>
                        <p class="text-primaryWhite font-medium">Check Out</p>
                    </button> -->
                </div>
            }
            <button class="flex flex-row w-[220px] rounded-md bg-darkGreen hover:bg-lightHoverGreen transition-[0.5s] px-4 py-2 items-center gap-2 justify-center"
                    (click)="checkOut()">
                <img src="../assets/icons/CheckOutIcon.png" class="size-[25px] nav_menu_icon"/>
                <p class="text-primaryWhite font-medium">Check Out</p>
            </button>
        </div>
    </div>
} @else {
    <button class="flex flex-row w-[220px] rounded-md bg-darkGreen hover:bg-lightHoverGreen transition-[0.5s] px-4 py-2 items-center gap-2 justify-center"
            (click)="checkIn()">
        <img src="../assets/icons/DoorCheckInIcon.png" class="size-[25px] nav_menu_icon"/>
        <p class="text-primaryWhite font-medium">Check In</p>
    </button>
}