<div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
    <div class="flex items-center w-[1000px] justify-center bg-primaryBlack/30 font-poppins">
        <form [formGroup]="form" class="flex flex-col w-full h-full">
            <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-center justify-between">
                    <p class="text-[24px] font-semibold text-primaryBlack">End activity</p> 
                    <button class="flex size-[30px] rounded-md bg-primaryBlack items-center justify-center 
                                hover:bg-red transition-[0.5s]"
                            (click)="modalService.close()">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                    </button>        
                </div>
                <p>Activity started: <span>{{activeLogStartDate}}</span></p>
                <div class="flex flex-col gap-2">
                    <label for="email" class="text-primaryBlack font-regular text-[12px]">Activity log description</label>
                    <textarea class="w-full min-h-[100px] max-h-[300px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-2 text-primaryBlack focus:outline-lightGreen" 
                            formControlName="Description" placeholder="Description" type="text"></textarea>
                </div>           
                <div class="flex flex-col gap-2">
                    <label for="email" class="text-primaryBlack font-regular text-[12px]">Duration (hours)</label>
                    <input class="h-[45px] w-[50%] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-2 text-primaryBlack focus:outline-lightGreen" 
                            formControlName="Duration" placeholder="Hours" type="number" min="0">
                </div>
                <div class="flex w-full justify-end">
                    <button (click)="endActivity()"
                            class="flex items-center bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px] w-[220px]">
                        End
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>