import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UserInfoCardComponent } from '../../components/user-info-card/user-info-card.component';
import { AuthService } from '../../services/auth.service';
import { Engagement } from '../../models/engagement';
import { EmployeeService } from '../../services/employee.service';
import { FormsModule } from '@angular/forms';
import { EngagementCardComponent } from '../../components/engagement-card/engagement-card.component';
import { ActivityLog } from '../../models/activityLog';
import { ActivityService } from '../../services/activity.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [UserInfoCardComponent, FormsModule, EngagementCardComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {
  public domain: any = '';
  public user: any = '';
  public imagePath: any;
  public displayDate: string = '';

  public netSalary: number = 0;
  public grossSalary: number = 0;

  public netPerHour: number = 0;
  public netPerDay: number = 0;
  public netPerYear: number = 0;

  public grossPerHour: number = 0;
  public grossPerDay: number = 0;
  public grossPerYear: number = 0;

  public engagements: Engagement[] = [];
  public filteredEngagements: any[] = [];
  public activities: ActivityLog[] = [];

  public filterActive: boolean = false;
  public filterScheduled: boolean = false;
  public filterPaused: boolean = false;
  public filterFinished: boolean = false;

  constructor(
    private authService: AuthService,
    private routes: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private employeeService: EmployeeService,
    private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    this.domain = sessionStorage.getItem('domain');
    this.loadData();
  }

  public loadData(): void {
    forkJoin({
      user: this.authService.getUser(),
      engagements: this.employeeService.getClientEngagements(),
    }).subscribe(
      ({ user, engagements }) => {
        this.user = user;
        this.netSalary = this.user.Financial.Net;
        this.grossSalary = this.user.Financial.Gross;
        this.calculateNetAndGross();
        this.displayDate = new Date(this.user.DateOfBirth).toISOString().substring(0, 10);
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.user.EmployeePicture
        );

        this.engagements = engagements;
        this.filteredEngagements = this.engagements;
        this.filterEngagements();

        console.log("User data loaded successfully.", { user, engagements });
      }
    );
  }

  public calculateNetAndGross(): void {
    this.netPerHour = parseFloat((this.netSalary / 22 / 8).toFixed(1));
    this.netPerDay = parseFloat((this.netSalary / 22).toFixed(1));
    this.netPerYear = this.netSalary * 12;

    this.grossPerHour = parseFloat((this.grossSalary / 22 / 8).toFixed(1));
    this.grossPerDay = parseFloat((this.grossSalary / 22).toFixed(1));
    this.grossPerYear = this.grossSalary * 12;
  }

  public filterEngagements(): void {
    if (this.filterActive || this.filterFinished || this.filterPaused || this.filterScheduled) {
      this.filteredEngagements = this.engagements.filter(
        engagement =>
          (this.filterActive && engagement.ProjectStatus === 'Active') ||
          (this.filterFinished && engagement.ProjectStatus === 'Finished') ||
          (this.filterPaused && engagement.ProjectStatus === 'Paused') ||
          (this.filterScheduled && engagement.ProjectStatus === 'Scheduled')
      );
    } else {
      this.filteredEngagements = this.engagements;
    }
  }
}

