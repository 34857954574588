import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectSource = new Subject<any>();
  createdPositions$ = this.projectSource.asObservable();

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient) { }

  public getById(projectId : any) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/getById/${projectId}`);
  }

  public getManagersProjects() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/getAll`);
  }

  // Todo
  public deleteEmployeeFromProject(employeeId : string, projectId : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/manager/fire/employee?employeeID=${employeeId}&projectID=${projectId}`,{});
  }


  public addTeamToProject(projectId : string, teamId : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/manager/hire/team?teamID=${teamId}&projectID=${projectId}`,{});
  }
}
