<div class="flex flex-col w-full gap-4 font-poppins overflow-y-auto">
    <app-user-info-card
        [firstName]="user.FirstName"
        [lastName]="user.LastName"
        [bio]="user.Bio"
        [dateOfBirth]="user.DateOfBirth"
        [email]="user.Email"
        [emergencyContact]="user.EmergencyContact"
        [employeeId]="user.EmployeeID"
        [atEmployeeProfilePage]="true"
        [employeeProfile]="true"

    />
    <div class="flex flex-col w-full justify-start bg-primaryWhite rounded-md p-4 gap-4">
        <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">PERSONAL INFO</p>
        <div class="flex flex-row w-full items-start justify-start justify-between gap-4">
            <div class="flex flex-col w-full items-start justify-start gap-2">
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">First name</p>
                    <p class="text-primaryBlack text-[18px]">{{user.FirstName}}</p>
                </div>
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">Last name</p>
                    <p class="text-primaryBlack text-[18px]">{{user.LastName}}</p>
                </div>
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">Date of Birth</p>
                    <p class="text-primaryBlack text-[18px]">{{displayDate}}</p>
                </div>
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">Emergency contact</p>
                    <p class="text-primaryBlack text-[18px]">{{user.EmergencyContact}}</p>
                </div>
            </div>
            <div class="flex flex-col w-full items-start justify-start gap-2">
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">Email address</p>
                    <p class="text-primaryBlack text-[18px]">{{user.Email}}</p>
                </div>
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">Company domain</p>
                    <p class="text-primaryBlack text-[18px]">{{user.Domain}}</p>
                </div>
                <div class="flex flex-col w-full items-start">
                    <p class="text-primaryBlack/50 text-[12px]">First name</p>
                    <p class="text-primaryBlack text-[18px]">{{user.FirstName}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto gap-4">        
        <div class="flex flex-row w-full items-center gap-4">
            <div class="flex flex-col w-full rounded-md bg-primaryWhite p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-between justify-center">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">NET SALARY</p>
                </div>
                <div class="flex flex-col w-full items-start justify-center gap-2">
                    <p class="text-[32px] font-bold text-lightGreen">${{netSalary}}</p>
                    <div class="flex flex-row items-center w-full justify-between justify-center">
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{netPerHour}}</span> /hour
                        </p>
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{netPerDay}}</span> /day
                        </p>
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{netPerYear}}</span> /year
                        </p>
                    </div>
                </div>
            </div>
        
            <div class="flex flex-col w-full rounded-md bg-primaryWhite p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-between justify-center">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">GROSS SALARY</p>
                </div>    
                <div class="flex flex-col w-full items-start justify-center gap-2">
                    <p class="text-[32px] font-bold text-lightGreen">${{grossSalary}}</p>
                    <div class="flex flex-row items-center w-full justify-between justify-center">
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{grossPerHour}}</span> /hour
                        </p>
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{grossPerDay}}</span> /day
                        </p>
                        <p class="text-[12px] font-light text-primaryBlack mt-2">
                            <span class="text-lightGreen font-bold">${{grossPerYear}}</span> /year
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
            <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">SALARY HISTORY</p>
            <div id="chart" ></div>
        </div> -->
    </div>
    <div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-4 gap-4 rounded-md bg-primaryWhite">
        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide uppercase">Engagements</p>
        <div class="flex flex-col border-[1px] rounded-md p-2 gap-2 justify-start items-start">
            <div class="flex flex-row items-center gap-2">
                <img src="../../../../assets/icons/FilterIcon.png" class="size-[15px]"/>
                <p class="text-primaryBlack text-[14px]">Filters</p>
            </div>
            <div class="flex flex-row gap-4 items-start">
                <p class="text-primaryBlack font-light text-[12px]">Project status</p>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterActive" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">ACTIVE</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterFinished" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">FINISHED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterPaused" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">PAUSED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterScheduled" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">SCHEDULED</p>
                </div>
            </div>
        </div>            
        <div class="flex flex-row gap-4 items-center">
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-active rounded-full me-1.5 flex-shrink-0"></span>Active</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-scheduled rounded-full me-1.5 flex-shrink-0"></span>Scheduled</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-paused rounded-full me-1.5 flex-shrink-0"></span>Paused</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-finished rounded-full me-1.5 flex-shrink-0"></span>Finished</span>
        </div>
        <div class="flex flex-row items-center jusitfy-start gap-4 overflow-x-auto">
            @for (engagement of filteredEngagements; track $index) {
                <app-engagement-card class="mt-2"
                    [engagement]="engagement"
                />
            }
        </div>
    </div>
</div>