import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { Task } from '../../models/task';
import { ProjectService } from '../../services/project.service';
import { TasksService } from '../../services/tasks.service';

@Component({
  selector: 'app-task-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './task-card.component.html',
  styleUrl: './task-card.component.css'
})
export class TaskCardComponent implements OnInit, AfterViewInit {
  
  @Input() task? : Task;

  @Output() taskDeleted = new EventEmitter<void>();
  @Output() taskEdited = new EventEmitter<void>();

  public project? : any;
  public employeePictureLink : any;

  constructor(private projectService : ProjectService){}

  ngOnInit(): void {
    console.log("Received task: ", this.task);
  }

  ngAfterViewInit(): void {
    // this.getProject();
  }

  // public getProject() : void {
  //   this.projectService.getById(this.task?.ProjectID).subscribe(
  //     (response : any) => {
  //       this.project = response;
  //     }
  //   )
  // }


  public editTask() : void {
    // const modalRef = this.modalService.open(
    //   EditTaskModalComponent,
    //   { backdrop : 'static', keyboard : true }
    // );

    // modalRef.componentInstance.task = this.task;

    // modalRef.componentInstance.taskEdited.subscribe(
    //   () => {
    //     this.taskEdited.emit();
    //   }
    // )
  }

  public deleteTask() : void {
    // const modalRef = this.modalService.open(
    //   WarningModalComponent,
    //   { backdrop : 'static', keyboard : true }
    // );

    // modalRef.componentInstance.confirmation.subscribe(
    //   () => {
    //     this.taskService.deleteTask(this.task?.ID).subscribe(
    //       (response : any) => {
    //         console.log("Task deleted successfully!", response);
    //         this.taskDeleted.emit();
    //       }
    //     )
    //   }
    // )
  }
}
