import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Form, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from '../../../services/activity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityLog } from '../../../models/activityLog';

@Component({
  selector: 'app-end-activity-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './end-activity-modal.component.html',
  styleUrl: './end-activity-modal.component.css'
})
export class EndActivityModalComponent implements OnInit{

  @Output() activityEnded = new EventEmitter<void>();

  public form! : FormGroup;
  public activeLogStartDate : string = '';
  public initialWorkedMillis: number = 0;
  public duration : number = 0;

  public activeLog? : ActivityLog;

  constructor(public modalService : NgbActiveModal,
    private fb : FormBuilder,
    private activityService : ActivityService
  ){}

  ngOnInit(): void {
    this.initializeForm();
    this.getActiveLog();
  }
  
  // public calculateCurrentTime() : void {
  //   const currDate = new Date();
  //   console.log("Currently: ",currDate.getTime());

  //   const startDate = this.activeLog?.Start;
  //   console.log("Started at: ", startDate);

  //   if(startDate){
  //     const worked = currDate.getTime() - startDate;
  //     console.log(worked);

  //     const currWorked = new Date(worked);
  //     console.log("Worked for: ", currWorked.getTime());

  //     const dateParsed = currWorked.toISOString().substring(0, 16);
  //     console.log("Parsed worked time: ", dateParsed.split('T')[1]);

  //     this.form.value.Duration = dateParsed.split('T')[1];
      
  //     const reversedWorkedToMilis = new Date('1970-01-01T00:32:00.00');
  //     console.log("Reversed to milis: ", reversedWorkedToMilis.getTime())
  //   }
  // }


  public calculateCurrentTime(): void {
    if (this.activeLog?.Start) {
      const currDate = new Date();
      this.initialWorkedMillis =
        currDate.getTime() - new Date(this.activeLog.Start).getTime();
  
      console.log("Initial: ", this.initialWorkedMillis);
      // Convert milliseconds to hours and minutes
      const totalMinutes = Math.floor(this.initialWorkedMillis / 60000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
  
      // Format as HH:mm
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      console.log('Calculated Worked Duration (HH:mm): ', formattedTime);
  
      // Set the value in the form
      this.form.patchValue({ Duration: formattedTime });
    }
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      'Description' : ['', Validators.required],
      'Duration' : [0, [Validators.required, Validators.min(0)]]
    });
  }

  public getActiveLog() : void {
    this.activityService.getActiveLog().subscribe(
      (response : ActivityLog) => {
        this.activeLog = response;
        console.log("ACTIVE LOG: ", this.activeLog);
        
        const date = new Date(this.activeLog.Start);
        const startDateAndTime = date.toISOString().split('T');
        const startTimeParsed = startDateAndTime[1].slice(0, 11);

        this.activeLogStartDate = startDateAndTime[0] + ' ' + startTimeParsed
        this.calculateCurrentTime();

      }
    );
  }

  // public endActivity() : void {
  //   if(this.form.valid && this.activeLog) {
  //     const formValue = this.form.value;
      
  //     console.log("Active log ID: ", this.activeLog.ID);
  //     console.log("Duration: ", formValue.Duration);
  //     console.log("Description: ", formValue.Description);

  //     const startDate = new Date(this.activeLog.Start);
  //     const endDate = new Date();

  //     const timeDifferenceMillis = endDate.getTime() - startDate.getTime();
  //     console.log("Difference in milliseconds: ", timeDifferenceMillis);

  //     console.log("Entered duration: ", formValue.Duration);
  //     const enteredDurationInMillis = formValue.Duration * 1000 * 3600;
  //     console.log("Entered duration in milliseconds: ", enteredDurationInMillis);

  //     let duration;

  //     formValue.Duration !== 0 ? duration = formValue.Duration : duration = timeDifferenceMillis;



  //     this.activityService.endActivity(this.activeLog?.ID, duration, formValue.Description).subscribe(
  //       (response : any) => {
  //         console.log(response);
  //         this.activityEnded.emit();
  //         this.modalService.close();
  //       }
  //     );
  //   }
  // }

  public endActivity(): void {
    if (this.form.valid && this.activeLog) {
      const formValue = this.form.value;
  
      const durationParts = formValue.Duration.split(':');
      const hours = parseInt(durationParts[0], 10) || 0; 
      const minutes = parseInt(durationParts[1], 10) || 0;
  
      const durationMillis =
        hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  
      const isUnchanged =
        this.initialWorkedMillis === durationMillis;
  
      console.log('Initial Worked Milliseconds: ', this.initialWorkedMillis);
      console.log('Final Duration in Milliseconds: ', durationMillis);
      console.log('Duration Unchanged: ', isUnchanged);
  
      const finalDurationMillis = isUnchanged
        ? this.initialWorkedMillis
        : durationMillis;
  
      console.log('Final Duration to Submit: ', finalDurationMillis);
  
      this.activityService.endActivity(this.activeLog.ID, finalDurationMillis, formValue.Description).subscribe(
        (response: any) => {
          console.log(response);
          this.activityEnded.emit();
          this.modalService.close();
          window.location.reload();
        }
      );
    }
  }
}
