import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginRequestBody } from '../models/LoginRequestBodyDTO';
import { TokenAppLoginResponse } from '../models/TokenAppLoginResponse';
import { RegisterRequestBody } from '../models/RegisterRequestBodyDTO';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public authStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn());
  public authStatus$: Observable<boolean> = this.authStatusSubject.asObservable();

  public isRefreshingToken = false; 
  public tokenRefreshedSubject = new Subject<void>();
  public tokenRefreshed$ = this.tokenRefreshedSubject.asObservable();

  public companyStatusSubject = new BehaviorSubject<boolean>(false);

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient,
    private router : Router, 
  ) {}
  
  public isLoggedIn(): boolean {
    const token = sessionStorage.getItem('token');
    return !!token;
  }
  
  public getUser() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/employee/getUser`, {responseType : 'json'});
  }

  public refreshToken() {
    const uuid = localStorage.getItem('deviceId')
    const employeeId = sessionStorage.getItem('employeeId');
    return this.http.get<any>(`${this.apiServerUrl}/auth/check/employee/refresh/token/${uuid}/${employeeId}`, { observe: 'response', withCredentials: true }).pipe(
      tap(response => {
        const refreshedAccessToken = response.headers.get('Accesstoken');
        console.log("Refreshed access token: ", refreshedAccessToken);
        if (refreshedAccessToken) {
          sessionStorage.setItem('token', refreshedAccessToken);
          this.tokenRefreshedSubject.next();
        } 
      })
    );
  }
  

  public logIn(logInDTO: LoginRequestBody) : Observable<TokenAppLoginResponse> {
    return this.http.post<TokenAppLoginResponse>(`${this.apiServerUrl}/auth/login`, logInDTO).pipe(
      tap((response: TokenAppLoginResponse) => {
        sessionStorage.setItem('token', response.Token);
        this.authStatusSubject.next(true);
      })
    );
  }

  public logOut() : void {
    const domain = sessionStorage.getItem('domain');
    const employeeId = sessionStorage.getItem('employeeId');
    const uuid = localStorage.getItem('deviceId');
    this.authStatusSubject.next(false);
    this.companyStatusSubject.next(false);
    this.router.navigate([`${domain}`]);
    sessionStorage.clear();
    this.http.get(`${this.apiServerUrl}/auth/logout?userID=${employeeId}&userType=employee&uuid=${uuid}&domain=${domain}`);
  }

  public signUp(dto : RegisterRequestBody) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/auth/register`, dto);
  }

  public getUserInfo(domain : any) : Observable<any> {
    const token = sessionStorage.getItem('token');
    var path;
    const headers = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${token}`
    });

    if(domain != null) {
      path = `/auth/getUser?domain=${domain}`
    } else {
      path = `/auth/getUser`
    }
    return this.http.get<any>(`${this.apiServerUrl}${path}`);
  }
 
  public magicLinkLogin(domain : string, body : any, uuid : string) : Observable<string> {
    return this.http.post<string>(`${this.apiServerUrl}/auth/${domain}/magicLink?UUID=${uuid}`, body);
  }

  public getToken(domain: string, email: string, code: string, uuid : string) : Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.apiServerUrl}/auth/${domain}/${email}/${code}/${uuid}`, {observe:'response'});
  }
  
}
