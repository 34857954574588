import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent implements OnInit{

  @Input() text : String = "";
  @Input() icon : String = "";
  @Input() function!: () => void; 
  @Input() width: String = "220px";
  @Input() primary: boolean = true;
  @Input() secondary: boolean = false;

  public hasCompanyColor : boolean = false;
  public companyColor : any;

  ngOnInit(): void {
    const color = sessionStorage.getItem('companyColor');
    this.companyColor = color;
    this.hasCompanyColor = true;
  }

}
