import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StartActivityModalComponent } from '../modals/start-activity-modal/start-activity-modal.component';
import { ActivityService } from '../../services/activity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EndActivityModalComponent } from '../modals/end-activity-modal/end-activity-modal.component';
import { ActivityLog } from '../../models/activityLog';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-activity-log-bar',
  standalone: true,
  imports: [],
  templateUrl: './activity-log-bar.component.html',
  styleUrl: './activity-log-bar.component.css'
})
export class ActivityLogBarComponent implements OnInit{

  @Input() activityExists : boolean = false;
  @Input() checkedIn? : boolean;

  public activeLog? : ActivityLog;
  public activityPaused : any;

  constructor(private modalService : NgbModal,
    private activityService : ActivityService,
    private toast : NgToastService
  ) {}

  ngOnInit(): void {
    this.checkForStartedActivity();
  }
  
  public checkForStartedActivity() : void {
    this.activityService.getActiveLog().subscribe(
      (response : ActivityLog) => {
        console.log("ACTIVE LOG BAR:", response);
        this.activeLog = response;
        if(response) {
          console.log(response.Duration);
          if(response.Duration === 0) {
            console.log("POSTOJI");
            this.activityExists = true;
            this.activityPaused = response.Breaks.some(breakItem => breakItem.Duration === 0);
          } else {
            console.log("ne POSTOJI");
            this.activityExists = false;
            this.activityPaused = false;
          }
        }
      },
      (error : HttpErrorResponse) => {
        this.activityExists = false;
        this.activityPaused = false;
        console.log("Error while fetching active log bar, ", error.message);
      }
    )
  }

  public startActivity() : void {
    const modalRef = this.modalService.open(
      StartActivityModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.activityStarted.subscribe(
      () => {
        this.toast.success("Activity started!", "Success!", 3000);
        this.checkForStartedActivity();
      }
    )
  }

  public endActivity() : void {
    const modalRef = this.modalService.open(
      EndActivityModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.activityEnded.subscribe(
      () => {
        console.log("Activity ended!");
        this.toast.success("Activity ended!", "Success!", 3000);
        this.activityExists = false;
      }
    )
  }

  public pauseActivity() : void {
    this.activityService.startPause().subscribe(
      (response : any) => {
        console.log("Activity paused");
        this.toast.success("Activity paused!", "Success!", 3000);
        this.activityPaused = true;
        console.log(response);
      }
    )
  }

  public resumeActivity() : void {
    this.activityService.resumeActivity().subscribe(
      (response : any) => {
        console.log("Activity resumed!");
        this.toast.success("Activity resumed!", "Success!", 3000);
        this.activityPaused = false;
      }
    )
  }

  public checkIn() : void {
    this.activityService.checkIn().subscribe(
      (response : any) => {
        this.checkedIn = true;
        console.log("Successfully checked in!", response);
        this.toast.success("Successfully checked in!", "Success!", 3000);
      }
    )
  }

  public checkOut() : void {
    this.activityService.checkOut().subscribe(
      (response : any) => {
        this.checkedIn = false;
        console.log("Successfully checked out!", response);
        this.toast.success("Successfully checked out!", "Success!", 3000);
      }
    )
  }
} 
