<div [ngClass]="{'not-selectable' : !isAdmin && !lightText}"
    class="flex flex-col font-poppins w-full items-center justify-center justify-between h-full p-1">
    <div class="flex flex-col w-full h-full gap-4">
        <div class="flex flex-row w-full items-center justify-between">
            <div class="flex flex-row items-center gap-2 w-full">
                <div
                    class="flex w-[30%] img-container rounded-full  bg-primaryWhite relative">
                    <img [src]="imageLink" class="rounded-full size-full">
                </div>
                <p class="font-bold text-lightGreen text-[24px] text-ellipsis overflow-hidden truncate"
                    [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28'}">{{companyName}}</p>
            </div>
        </div>
        @if(userLogged) {
        <div class="note-selectable flex flex-col w-full h-full gap-2">

            <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                [ngClass]="{'active-custom-button':showProfile,'custom-button':!showProfile}"
                (click)="viewUserProfile()">
                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                    [ngStyle]="{'background-color':'var(--icon-color)'}">
                    <img src="../../../assets/icons/UserIcon.png"
                        class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                        [ngClass]="{'opacity-100' : showProfile,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showProfile}">
                </div>
                <p [ngStyle]="{'color':'var(--text-color)'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Profile</p>
            </div>

            <!-- <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                [ngClass]="{'active-custom-button':showNotifications,'custom-button':!showNotifications}"
                (click)="viewNotifications()">
                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                    [ngStyle]="{'background-color':'var(--icon-color)'}">
                    <img src="../../../assets/icons/NotificationIcon.png"
                        class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                        [ngClass]="{'opacity-100' : showNotifications,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showNotifications}">
                </div>
                <p [ngStyle]="{'color':'var(--text-color)'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Notifications</p>
            </div> -->

            @if (loggedUser.Role === "ProjectManager") {
                <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                    [ngClass]="{'active-custom-button':showProjects,'custom-button':!showProjects}"
                    (click)="viewProjects()">
                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                        [ngStyle]="{'background-color':'var(--icon-color)'}">
                        <img src="../../../assets/icons/ProjectsIcon.png"
                            class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                            [ngClass]="{'opacity-100' : showProjects,
                                            'nav_menu_icon' : lightText,
                                            'opacity-50' : !showProjects}">
                    </div>
                    <p [ngStyle]="{'color':'var(--text-color)'}"
                        class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Projects</p>
                </div>
            }

            <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                [ngClass]="{'active-custom-button':showVacancies,'custom-button':!showVacancies}"
                (click)="viewVacancies()">
                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                    [ngStyle]="{'background-color':'var(--icon-color)'}">
                    <img src="../../../assets/icons/VacanciesIcon.png"
                        class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                        [ngClass]="{'opacity-100' : showVacancies,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showVacancies}">
                </div>
                <p [ngStyle]="{'color':'var(--text-color)'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Vacancies</p>
            </div>

            <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                [ngClass]="{'active-custom-button':showEmployeeAssets,'custom-button':!showEmployeeAssets}"
                (click)="viewEmployeeAssets()">
                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                    [ngStyle]="{'background-color':'var(--icon-color)'}">
                    <img src="../../../assets/icons/AssetsIcon.png"
                        class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                        [ngClass]="{'opacity-100' : showEmployeeAssets,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showEmployeeAssets}">
                </div>
                <p [ngStyle]="{'color':'var(--text-color)'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Your assets</p>
            </div>


            <div class="flex flex-row w-full custom-button items-center cursor-pointer group rounded-full p-2 transition-[1s] not-selectable"
                [ngClass]="{'active-custom-button':showActivities,'custom-button':!showActivities}"
                (click)="viewActivities()">
                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]"
                    [ngStyle]="{'background-color':'var(--icon-color)'}">
                    <img src="../../../assets/icons/ActivitiesIcon.png"
                        class="nav_menu_icon size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]"
                        [ngClass]="{'opacity-100' : showActivities,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showActivities}">
                </div>
                <p [ngStyle]="{'color':'var(--text-color)'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px] custom-text">Activities</p>
            </div>

            <!-- <div (mouseenter)="handleHovered('Profile')" (mouseleave)="handleUnHovered('Profile')"
                [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showProfile || isHoveredProfile ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"
                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                (click)="viewUserProfile()">
                <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showProfile || isHoveredProfile ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"
                    class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                    <img src="../../../assets/icons/UserIcon.png" [ngClass]="{'opacity-100' : showProfile,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showProfile}"
                        class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
                <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showProfile ? '1' : '0.5'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Profile</p>
            </div>

            <div (mouseenter)="handleHovered('Notifications')" (mouseleave)="handleUnHovered('Notifications')"
                [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                            'background-color': showNotifications || isHoveredNotifications ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"
                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                (click)="viewNotifications()">
                <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showNotifications || isHoveredNotifications ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"
                    class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                    <img src="../../../assets/icons/NotificationIcon.png" [ngClass]="{'opacity-100' : showNotifications,
                                    'nav_menu_icon' : lightText,
                                    'opacity-50' : !showNotifications}"
                        class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
                <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showNotifications ? '1' : '0.5'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Notifications</p>
            </div> -->

            <!-- <div (mouseenter)="handleHovered('Vacancies')" (mouseleave)="handleUnHovered('Vacancies')"
                [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showVacancies || isHoveredVacancies ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"
                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                (click)="viewVacancies()">
                <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showVacancies || isHoveredVacancies ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"
                    class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                    <img src="../../../assets/icons/VacanciesIcon.png" [ngClass]="{'opacity-100' : showVacancies,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showVacancies}"
                        class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
                <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showVacancies ? '1' : '0.5'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Vacancies</p>
            </div> -->
            <!-- 
            <div (mouseenter)="handleHovered('Assets')" (mouseleave)="handleUnHovered('Assets')"
                [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showEmployeeAssets || isHoveredAssets ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"
                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                (click)="viewEmployeeAssets()">
                <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showEmployeeAssets || isHoveredAssets ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"
                    class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                    <img src="../../../assets/icons/AssetsIcon.png" [ngClass]="{'opacity-100' : showEmployeeAssets,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showEmployeeAssets}"
                        class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
                <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showEmployeeAssets ? '1' : '0.5'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Your assets</p>
            </div>

            <div (mouseenter)="handleHovered('Activities')" (mouseleave)="handleUnHovered('Activities')"
                [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showActivities || isHoveredActivities ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"
                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                (click)="viewActivities()">
                <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showActivities || isHoveredActivities ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"
                    class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                    <img src="../../../assets/icons/ActivitiesIcon.png" [ngClass]="{'opacity-100' : showActivities,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showActivities}"
                        class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
                <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showActivities ? '1' : '0.5'}"
                    class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Activities</p>
            </div> -->

            <!-- <div (mouseenter)="handleHovered('Finance')"
                    (mouseleave)="handleUnHovered('Finance')"
                    [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showFinance || isHoveredFinance ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"  
                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                    (click)="viewFinance()">
                    <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showFinance || isHoveredFinance ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"   
                        class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                        <img src="../../../assets/icons/FinanceIcon.png"
                            [ngClass]="{'opacity-100' : showFinance,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showFinance}"
                            class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                    </div>
                    <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showFinance ? '1' : '0.5'}"
                        class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Finance</p>
                </div> -->

            <!-- <div (mouseenter)="handleHovered('Engagements')"
                    (mouseleave)="handleUnHovered('Engagements')"
                    [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                'background-color': showEngagements || isHoveredEngagements ? (lightText ? 'rgba(245, 245, 250, 0.2)' : 'rgba(40, 43, 40, 0.2)') : 'rgba(245, 245, 250, 0)'}"  
                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                    (click)="viewEngagements()">
                    <div [ngStyle]="{'background' : lightText ? 'rgba(245, 245, 250, 1)' : 'rgba(40, 43, 40, 1)', 
                                    'background-color': showFinance || isHoveredEngagements ? 'rgba(40, 43, 40, 0.2)' : 'rgba(40, 43, 40, 0.2)'}"   
                        class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center transition-[0.5s]">
                        <img src="../../../assets/icons/PositionIcon.png"
                            [ngClass]="{'opacity-100' : showEngagements,
                                        'nav_menu_icon' : lightText,
                                        'opacity-50' : !showEngagements}"
                            class="size-[20px] 2xl:size-[30px] opacity-50 group-hover:opacity-100 transition-[0.7s]">
                    </div>
                    <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28', 'opacity' : showEngagements ? '1' : '0.5'}"
                        class="group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Engagements</p>
                </div> -->
        </div>
        }
    </div>
    @if (userLogged) {
    <div class="flex flex-col w-full gap-2 not-selectable">
        <p [ngStyle]="{'color':'var(--text-color)'}" class="text-[12px] 2xl:text-[16px] tracking-widest opacity-50">USER
            PROFILE</p>
        <div class="flex flex-row w-full items-center justify-start justify-between">
            <div class="flex flex-row items-center">
                <div class="flex flex-col items-start gap-1">
                    <p [ngStyle]="{'color':'var(--text-color)'}" class="font-medium text-[12px] 2xl:text-[18px]">
                        {{fullName}}</p>
                    <p [ngStyle]="{'color':'var(--text-color)'}"
                        class="font-light sm:text-[8px] xl:text-[9px] 2xl:text-[12px]">{{email}}</p>
                </div>
            </div>
            <div
                class="custom-button items-center justify-center rounded-full size-[40px] p-2 transition-[0.5s] hover:bg-primaryBlack/50 group cursor-pointer">
                <img src="../../../assets/icons/SignOutIcon.png" (click)="authService.logOut()"
                    [ngClass]="{'nav_menu_icon' : lightText}"
                    class="opacity-50 group-hover:opacity-100 transition-[0.7s]">
            </div>
        </div>
    </div>
    }
</div>