import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Engagement } from '../../models/engagement';
import { EmployeeService } from '../../services/employee.service';
import { EngagementCardComponent } from '../../components/engagement-card/engagement-card.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-engagements',
  standalone: true,
  imports: [EngagementCardComponent, FormsModule],
  templateUrl: './engagements.component.html',
  styleUrl: './engagements.component.css'
})
export class EngagementsComponent implements OnInit{
  
  public engagements : Engagement[] = [];
  public filteredEngagements : any[] = [];

  public filterActive : boolean = false;
  public filterScheduled : boolean = false;
  public filterPaused : boolean = false;
  public filterFinished : boolean = false;

  constructor(private employeeService : EmployeeService,
    private authService : AuthService
  ){}

  ngOnInit(): void {
    this.getEngagements();
  }

  public getEngagements() {
    this.employeeService.getClientEngagements().subscribe(
      (response : Engagement[]) => {
        this.engagements = response;
        this.filteredEngagements = this.engagements
        this.filterEngagements();
        console.log("Engagements: ", this.engagements);
      }
    )
  }

  public filterEngagements() : void {
    if(this.filterActive || this.filterFinished || this.filterPaused || this.filterScheduled) {
      this.filteredEngagements = this.engagements.filter(engagement => 
        (this.filterActive && engagement.ProjectStatus === 'Active') || (this.filterFinished && engagement.ProjectStatus === 'Finished') ||
        (this.filterPaused && engagement.ProjectStatus === 'Paused') || (this.filterScheduled && engagement.ProjectStatus === 'Scheduled')
      );
    } else {
      this.filteredEngagements = this.engagements
    }
  }
}
