import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Employee } from '../models/employee';
import { CompanyEmployee } from '../models/companyEmployee';
import { Engagement } from '../models/engagement';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private skillSource = new Subject<any>();
  skillSelected$ = this.skillSource.asObservable();

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  public getEmployee(userId : string) : Observable<any> { 
    const companyId = sessionStorage.getItem('company');
    const body = {
      companyId : companyId,
      employeeId : userId
    }
    return this.http.post<any>(`${this.apiServerUrl}/auth/findEmployeeById`, body);
  }

  public requestDayOff(startDate : number, message: string, numberOfDays : number) : Observable<any> {
    const domain = sessionStorage.getItem('domain')
    const role = sessionStorage.getItem('Role')

    if(role === "Employee"){
      return this.http.post<any>(`${this.apiServerUrl}/employee/requestDayOff?message=${message}&startDate=${startDate}&daysRequested=${numberOfDays}`, {})
    }
    else {
      return this.http.post<any>(`${this.apiServerUrl}/employee/managerDayOff?message=${message}&startDate=${startDate}&daysRequested=${numberOfDays}`, {})
    }
  }

  public getEngagements(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/get/all/engagements/${employeeId}`)
  }

  public getEmployeeImage(employeeId : string, companyId : any) : Observable<string>{
    return this.http.get<string>(`${this.apiServerUrl}/S3/generateS3Link/${employeeId}`)
  }

  public getUserProfilePicutre() : Observable<any>{
    const domain = sessionStorage.getItem('domain')
    return this.http.get<any>(`${this.apiServerUrl}/S3/viewProfilePicture`);
  }

  public getClientEngagements() : Observable<Engagement[]> {
    const clientId = sessionStorage.getItem("employeeId");
    return this.http.get<Engagement[]>(`${this.apiServerUrl}/employee/get/all/engagements/client/${clientId}`)
  }

  public getEmployeePicture(defaultPicture : boolean, employeeId : string) : any{
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = employeeId;
      companyId = company;
    }
    this.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        const employeePictureLink = response;
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + employeePictureLink);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while getting s3 image link, ", error.message);
      }
    )
  }


  public getEmployeeById(employeeId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/employee/manager/findEmployeeById?employeeID=${employeeId}`);
  }

  // get employees that are available for adition to project
  public getEmployees(projectId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/get/employees/for/project?projectID=${projectId}`);
  }


  public addEmployeeToProject(employeeId : string, projectId : string) : Observable<any> { 
    return this.http.post<any>(`${this.apiServerUrl}/project/manager/hire/employee?employeeID=${employeeId}&projectID=${projectId}`,{});
  }

  public getEmployeeImagePortal(employeeId : string) : Observable<string> {
    return this.http.get<any>(`${this.apiServerUrl}/S3/portal/generateS3Link/${employeeId}`);
  }
}