import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-request-day-off-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule ],
  templateUrl: './request-day-off-modal.component.html',
  styleUrl: './request-day-off-modal.component.css'
})
export class RequestDayOffModalComponent implements OnInit{
  
  form!: FormGroup;
  public today : string = '';

  @Output() requestCreated = new EventEmitter<void>();

  constructor(public modalService : NgbActiveModal,
    private fb: FormBuilder,
    private employeeService : EmployeeService
  ){}

  ngOnInit(): void {
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() + 1);
    this.today = todaysDate.toISOString().split('T')[0];

    this.form = this.fb.group({
      Message: ['', Validators.required],
      StartDate: ['', [Validators.required, this.dateValidator.bind(this)]],
      NumberOfDaysRequested: ['', Validators.required],
    });
  }

  public dateValidator(control : AbstractControl) : { [key : string] : boolean } | null {
    const inputDate = new Date(control.value);
    const todayDate = new Date(this.today);

    if(control.value && inputDate < todayDate) {
      return {'invalidDate' : true}
    } 

    return null;
  }

  public sendRequest() {
    if(this.form.valid){
      const formValue = this.form.value;

      const startDate = new Date(formValue.StartDate);
      const startDateInMillis = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
      
      const numberOfDaysRequestedInMillis = formValue.NumberOfDaysRequested * 24 * 60 * 60 * 1000;

      console.log(startDateInMillis),
      console.log(numberOfDaysRequestedInMillis);
      this.employeeService.requestDayOff(startDateInMillis, formValue.Message, numberOfDaysRequestedInMillis).subscribe(
        (response : any) => {
          console.log("Request successfully created, ", response);
          this.requestCreated.emit();
          this.modalService.close();
        }
      )
    }
  }
}
