<div class="flex flex-col w-full h-full items-start font-poppins p-2 gap-2">
    <div class="flex flex-row w-full gap-2 items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Project details</p>
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleGeneral()">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showGeneral,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                    <svg class="w-4 h-4 me-2" [ngClass]="{'text-lightGreen':showGeneral,
                            'group-hover:text-bg-primaryBlack/60' : !showGeneral}" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                    </svg>
                    General
                </a>
            </li>

            <li class="me-2" (click)="toggleMembers()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showMembers,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showMembers}">
                    <svg class="w-4 h-4 me-2" [ngClass]="{'text-lightGreen':showMembers,
                            'group-hover:text-bg-primaryBlack/60' : !showMembers}" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor">
                        <circle cx="9.00098" cy="6" r="4" fill="currentColor" />
                        <ellipse cx="9.00098" cy="17.001" rx="7" ry="4" fill="currentColor" />
                        <path
                            d="M20.9996 17.0005C20.9996 18.6573 18.9641 20.0004 16.4788 20.0004C17.211 19.2001 17.7145 18.1955 17.7145 17.0018C17.7145 15.8068 17.2098 14.8013 16.4762 14.0005C18.9615 14.0005 20.9996 15.3436 20.9996 17.0005Z"
                            fill="currentColor" />
                    </svg>
                    Members
                </a>
            </li>

            <li class="me-2" (click)="toggleTasks()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 gap-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showTasks,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showTasks}">
                    <svg width="18px" heigth="18px" [ngClass]="{'text-lightGreen':showTasks,
                            'group-hover:text-bg-primaryBlack/60' : !showTasks}" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" class="icon" version="1.1">
                        <path
                            d="M864 512a32 32 0 0 0-32 32v96a32 32 0 0 0 64 0v-96a32 32 0 0 0-32-32zM881.92 389.44a23.68 23.68 0 0 0-5.76-2.88 19.84 19.84 0 0 0-6.08-1.92 32 32 0 0 0-28.8 8.64A32 32 0 0 0 832 416a32 32 0 1 0 64 0 33.6 33.6 0 0 0-9.28-22.72z"
                            fill="currentColor" />
                        <path
                            d="M800 128h-32a96 96 0 0 0-96-96H352a96 96 0 0 0-96 96H224a96 96 0 0 0-96 93.44v677.12A96 96 0 0 0 224 992h576a96 96 0 0 0 96-93.44V736a32 32 0 0 0-64 0v162.56a32 32 0 0 1-32 29.44H224a32 32 0 0 1-32-29.44V221.44A32 32 0 0 1 224 192h32a96 96 0 0 0 96 96h320a96 96 0 0 0 96-96h32a32 32 0 0 1 32 29.44V288a32 32 0 0 0 64 0V221.44A96 96 0 0 0 800 128z m-96 64a32 32 0 0 1-32 32H352a32 32 0 0 1-32-32V128a32 32 0 0 1 32-32h320a32 32 0 0 1 32 32z"
                            fill="currentColor" />
                        <path
                            d="M712.32 426.56L448 721.6l-137.28-136.32A32 32 0 0 0 265.6 630.4l160 160a32 32 0 0 0 22.4 9.6 32 32 0 0 0 23.04-10.56l288-320a32 32 0 0 0-47.68-42.88z"
                            fill="currentColor" />
                    </svg>
                    Tasks
                </a>
            </li>
        </ul>
    </div>
    @if (showGeneral){
    <div class="flex flex-col h-auto w-full items-start justify-between justify-start gap-4">
        <div class="flex flex-row w-full items-center h-auto gap-4">
            <div class="flex flex-col w-full xl:w-full rounded-md bg-primaryWhite p-4 gap-2 relative">
                <div class="flex flex-col w-full items-start justify-between">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">General
                    </p>
                    <p class="font-semibold text-[32px] xl:text-[46px] text-primaryBlack mb-4">
                        {{displayedProject?.Name}}</p>
                    <hr class="w-full text-primaryBlack/50 mb-1">
                    <div class="flex flex-row items-center w-full gap-2">
                        <img src="../../../assets/icons/DateIcon.png" class="size-[16px] xl:size-[20px]" />
                        <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">{{startDateParsed}} -
                            {{endDateParsed}}</p>
                    </div>
                    <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">Duration : <span
                            class="font-medium">{{displayedProject?.Duration}} days</span></p>
                    <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">
                        Status:
                        <span [ngClass]="{'text-lightGreen' : displayedProject?.Status == 'Active' || displayedProject?.Status == 'Successfully archived',
                                                'text-darkBlue' : displayedProject?.Status == 'Scheduled',
                                                'text-lightHoverGreen' : displayedProject?.Status == 'Finished',
                                                'text-lightYellow' : displayedProject?.Status == 'Paused'}"
                            class="font-medium">
                            {{displayedProject?.Status}}
                        </span>
                    </p>
                </div>
            </div>


            <!-- Description -->

            <div class="flex flex-col w-full h-full rounded-md bg-primaryWhite p-4 gap-2">
                <div class="flex flex-col w-full items-start justify-between">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">
                        Description</p>
                    <p class="text-[12px] 2xl:text-[16px] font-regular mt-4">{{displayedProject?.Description}}</p>
                </div>
            </div>
        </div>


        <!-- Budget and expenses -->
        <div class="flex flex-row w-full h-auto gap-4">
            <div class="flex flex-col w-full xl:w-[50%] h-full xl:justify-between rounded-md bg-white p-4 relative gap-2 xl:gap-4">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">Budget</p>
                </div>
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="text-[32px] xl:text-[46px] font-bold text-lightGreen">${{displayedProject.Budget}}</p>
                    <img src="../../../assets/icons/IncomeIcon.png" class="size-[75px] xl:size-[55px]">
                </div>
                <p class="text-[12px] xl:text-[16px] font-light text-primaryBlack">The initial budget of the project</p>
            </div>

            <div class="flex flex-col w-full xl:w-[50%] xl:justify-between h-full rounded-md bg-white p-4 gap-2 xl:gap-4">
                <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">Expenses</p> 
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="text-[32px] xl:text-[46px] font-bold text-lightRed">Cost needs to be implemented</p>
                    <img src="../../../assets/icons/ExpenseIcon.png" class="size-[75px] xl:size-[55px]">
                </div>
                <p class="text-[12px] xl:text-[16px] font-light text-primaryBlack">Current project implementation costs</p>
            </div>
        </div>
    </div>
    }
    @if(showMembers){
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4 relative overflow-y-auto">
            <!-- <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search for members">
            </div> -->
            <div class="flex flex-col w-full gap-4">
                <div class="flex flex-col w-full gap-2">
                    <div class="flex flex-row w-full gap-2 items-center">
                        <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">TEAMS</p>
                        <button class="flex rounded-md bg-darkGreen p-2 gap-2 items-center justify-center hover:bg-lightGreen transition-[0.5s]"
                                (click)="openAddTeamModal()">
                            <img src="../../../assets/icons/PlusIcon.png" class="size-[15px] nav_menu_icon">
                            <p class="text-[12px] text-primaryWhite">Add new</p>
                        </button>
                    </div>
                    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
                        @for (team of teams; track $index) {
                            <!-- <app-team-card 
                                [teamId]="team"
                                [displayedOnProject]="true"
                                [projectId]="projectId"
                                (teamRemovedFromProject)="handleTeamRemovedFromProject()"
                            /> -->
                            <app-team-card 
                                [teamId]="team" 
                                (teamDeleted)="handleTeamRemovedFromProject()">
                            </app-team-card>
                        }
                    </div>
                </div>
                <hr class="w-full text-primaryBlack/40">
                <div class="flex flex-col w-full gap-2">
                    <div class="flex flex-row w-full gap-2 items-center">
                        <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">MEMBERS</p>
                        <button class="flex rounded-md bg-darkGreen p-2 gap-2 items-center justify-center hover:bg-lightGreen transition-[0.5s]"
                                (click)="addMembers()">
                            <img src="../../../assets/icons/PlusIcon.png" class="size-[15px] nav_menu_icon">
                            <p class="text-[12px] text-primaryWhite">Add new</p>
                        </button>
                    </div>                    
                    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
                        @for (member of members; track $index) {
                            <!-- <app-employee-card
                                [userId]="member"
                                [displayedOnProject]="true"
                                [projectId]="projectId"
                                (memberRemovedFromProjet)="handleMemberRemovedFromProject()"
                            /> -->
                            <app-employee-card 
                                [employeeId]="member"
                                (employeeDeletedFromProject)="handleEmployeeRemovedFromProject()">
                            </app-employee-card>
                        }
                    </div>
                </div>
            </div>
        </div> 

    }
    @if(showTasks){

    }
</div>