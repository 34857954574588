import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NgToastService } from 'ng-angular-popup';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(NgToastService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage: string;

      if (typeof error.error === 'string') {
        errorMessage = error.error;
      } else if (error.error?.message) {
        errorMessage = error.error.message;
      } else {
        errorMessage = 'An unexpected error occurred';
      }

      if(errorMessage !== "Didn't find active logs." && errorMessage !== "the provided hex string is not a valid ObjectID" &&
      errorMessage !== 'http: named cookie not present' &&
      errorMessage !== "An unexpected error occured" && error.message !== "Unauthorized: Unauthorized, the token header is empty."
      ){
        toastService.danger(errorMessage, 'Error!', 5000);
      }

      return throwError(() => error);
    })
  );
};
