<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Assets</p> 
    </div>
    <div class="flex flex-col w-full h-auto p-4 gap-4 bg-primaryWhite rounded-md">
        <div class="flex flex-col w-full gap-2">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">AVAILABLE FOR RESERVATIONS</p>
            <p class="text-[14px] font-light text-primaryBlack/60">Choose one of the following assets that you want to ask for permission to use.</p>
        </div>        
        <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            @if (availableAssets.length > 0){
            @for (asset of availableAssets; track $index) {
                <app-equipment-card 
                    [function]="callRentAsset(asset.Asset)"
                    [availableNumber]="asset.AvailbleAssetNumber"
                    [name]="asset.Asset.Name"
                    [serialNumber]="asset.Asset.SerialNumber"
                    [permission]="asset.Asset.Permission"
                    [assetId]="asset.Asset.ID"
                ></app-equipment-card>
                <!-- <div class="flex flex-row gap-2 p-4 w-auto max-w-[400px] border-[1px] rounded-md hover:translate-y-[-5px] hover:shadow-xl 
                            items-center transition-[0.5s] cursor-pointer"
                            (click) = "rentAsset(asset.Asset)"
                    > 
                    <div class="flex size-[80px] img-container rounded-sm border-[1px] border-primaryBlack/20">
                        @if (hasPicture) {
                            <img [src]="asset.Asset.assetImage" class="rounded-sm size-full"/>
                        } @else {
                            <img src="../../../../assets/icons/AssetsIcon.png" class="rounded-full"/>
                        }
                    </div>
                    <div class="flex flex-col gap-2">
                        <p class="text-primaryBlack font-medium text-[16px]">{{asset.Asset.Name}}</p>
                        <div class="flex flex-col">
                            @if (asset.Asset.SerialNumber) {
                            <p class="text-primaryBlack font-light text-[14px]">Serial number: <span class="font-semibold">{{asset.Asset.SerialNumber}}</span></p>
                            }
                            <p class="text-primaryBlack font-light text-[14px]">Requires approval: 
                                @if (asset.Asset.Permission) {
                                    <span class="font-semibold text-lightRed">YES</span>
                                } @else {
                                    <span class="font-semibold text-lightGreen">NO</span>
                                }
                            </p>
                            <p class="text-primaryBlack font-light text-[14px]">Number of available assets: <span class="font-semibold">{{asset.AvailbleAssetNumber}}</span></p>
                        </div>
                    </div>
                </div> -->
            }
        }
        </div>
    </div>
    <div class="flex flex-col w-full h-auto p-4 gap-4 bg-primaryWhite rounded-md">
        <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">YOUR PREVIOUS RESERVATIONS</p>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            @if (assetHistory === null) {
                <div class="flex w-full col-span-full">
                    <p class="text-primaryBlack font-semibold text-[24px]">
                        There are no assets to display. <br>
                        <span class="font-light text-[16px]">You can book your first asset by pressing <span class="font-medium text-lightGreen">one of the assets</span> from the list in the section above.</span>
                    </p>
                </div>
            } @else {
                @for (assetHist of assetHistory; track $index) {
                    <app-equipment-history-card
                        [function]="callReturnAsset(assetHist.projectID,assetHist.assetID)"
                        [assetId]="assetHist.assetID"
                        [startDate]="assetHist.StartDate"
                        [endDate]="assetHist.EndDate"
                        [name]="assetHist.name"
                    >
                    </app-equipment-history-card>
                    <!-- <div class="flex flex-col gap-2 p-4 max-w-[400px] border-[1px] rounded-md custom-border transition-[0.5s]">
                        <div class="flex flex-row gap-2 items-center w-full">
                            <div class="flex size-[80px] img-container rounded-sm border-[1px] border-primaryBlack/20">
                                @if (hasPicture) {
                                    <img [src]="assetHist.assetImage" class="rounded-sm size-full"/>
                                } @else {
                                    <img src="../../../assets/icons/AssetsIcon.png" class="rounded-sm"/>
                                }
                            </div>
                            <div class="flex flex-col gap-2">
                                <p class="text-primaryBlack font-medium text-[16px]">{{assetHist.name}}</p>
                                <div class="flex flex-col">
                                    <p class="text-primaryBlack font-light text-[14px]">Start date: <span class="font-semibold">{{getDate(assetHist.StartDate)}}</span></p>
                                    @if (assetHist.EndDate === -1) {
                                        <p class="text-primaryBlack font-light text-[14px]">Returned: <span class="font-semibold text-lightRed">NO</span></p>
                                    }
                                    @else {
                                        <p class="text-primaryBlack font-light text-[14px]">Returned: <span class="font-semibold text-lightGreen">YES</span></p>
                                        <p class="text-primaryBlack font-light text-[14px]">End date: <span class="font-semibold">{{getDate(assetHist.EndDate)}}</span></p>
                                    }
                                </div>
                            </div>
                        </div>
                        @if (assetHist.EndDate === -1) {
                            <div class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium border-[1px] 
                                        regular-button transition-[0.5s] uppercase cursor-pointer" (click)="returnAsset(assetHist.projectID,assetHist.assetID)">
                                Return asset
                            </div>
                        }
                    </div>  -->
                }
            }
        </div>
    </div>
</div>