import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-modal',
  standalone: true,
  imports: [],
  templateUrl: './warning-modal.component.html',
  styleUrl: './warning-modal.component.css'
})
export class WarningModalComponent {
  @Output() confirmation = new EventEmitter<void>();

  constructor(private modalService : NgbActiveModal){}

  public performAction() {
    this.confirmation.emit();
    this.closeModal();
  }

  public closeModal() {
    this.modalService.close();
  }
}
