<div [ngStyle]="{'background': team.ColorHash}"
    class="w-full font-poppins flex flex-col justify-between items-center max-w-full md:max-w-md lg:max-w-lg xl:max-w-xl border border-gray-200 p-4 sm:p-6 lg:p-8 hover:shadow-lg rounded-lg transition-all cursor-pointer relative">
    <button (click)="deleteTeamFromProject($event)"
        class="absolute top-3 right-3 flex items-center gap-1 opacity-40 hover:opacity-100 transition-opacity">
        <img src="../../../assets/icons/TrashIcon.png" class="w-3 h-3 sm:w-4 sm:h-4"/>
        <span class="text-xs sm:text-sm text-gray-700">Remove</span>
    </button>
    <div class="text-center mt-4">
        <p class="text-lg sm:text-xl lg:text-2xl font-semibold text-primaryBlack">{{ team.Name }}</p>
    </div>
    <div class="flex items-center gap-2 mt-4 sm:mt-6">
        <p class="text-sm sm:text-base lg:text-lg font-semibold text-primaryBlack">{{ team.Employees.length }}</p>
        <ng-container *ngIf="team.Employees.length === 1; else multipleEmployees">
            <img src="../../../assets/icons/UserIcon.png" class="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6"/>
        </ng-container>
        <ng-template #multipleEmployees>
            <ng-container *ngIf="team.Employees.length <= 3">
                <img src="../../../assets/icons/EmployeesIcon.png" class="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6"/>
            </ng-container>
            <ng-container *ngIf="team.Employees.length > 3">
                <div class="relative flex items-center">
                    <img src="../../../assets/icons/EmployeesIcon.png" class="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6"/>
                    <p class="absolute text-xs sm:text-sm lg:text-base font-medium text-primaryBlack" style="top: -4px; right: -4px;">+</p>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>