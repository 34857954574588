import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { Task } from 'zone.js/lib/zone-impl';
import { CompanyService } from '../../services/company.service';
import { NavigationService } from '../../services/navigation.service';
import { CommonModule } from '@angular/common';
import { EmployeeCardComponent } from '../../components/employee-card/employee-card.component';
import { TeamCardComponent } from '../../components/team-card/team-card.component';
import { AddEmployeesToProjectModalComponent } from '../../components/modals/add-employees-to-project-modal/add-employees-to-project-modal.component';
import { AddTeamToProjectModalComponent } from '../../components/modals/add-team-to-project-modal/add-team-to-project-modal.component';

@Component({
  selector: 'app-project-details',
  standalone: true,
  imports: [CommonModule, EmployeeCardComponent, TeamCardComponent, AddEmployeesToProjectModalComponent, AddTeamToProjectModalComponent],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.css'
})
export class ProjectDetailsComponent implements OnInit{

  public projectId : string = '';

  public showGeneral : boolean = true;
  public showTasks : boolean = false;
  public showMembers : boolean = false;

  //public project : any;
  public displayedProject : any = {};
  public members : any[] = [];
  public teams : any[] = [];
  public startDateParsed : string = "";
  public endDateParsed : string = "";

  public editingDescription : boolean = false;


  
  constructor(
    private projectService : ProjectService,
    private rotue : ActivatedRoute,
    private modalService : NgbModal,
    private cdr : ChangeDetectorRef,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    const projectIdFromRoute = this.rotue.snapshot.paramMap.get('id');
    if(projectIdFromRoute !== null) {
      this.projectId = projectIdFromRoute;
      //console.log("Project id: ", this.projectId);
    }
    this.getProject();
  }

  public getProject() : void {
    this.projectService.getById(this.projectId).subscribe(
      (response : any) => {
        this.displayedProject = {
          Name : response.Name,
          StartDate : response.StartDate,
          EndDate : response.EndDate,
          Duration : response.ProjectDuration,
          Status : response.ProjectStatus,
          Description : response.Description,
          Budget : response.Budget
        };
        const startDate = new Date(this.displayedProject.StartDate);
        const endDate = new Date(this.displayedProject.EndDate);
        this.members = response.Employees;
        this.teams = response.Teams;

        console.log("These are the teams", this.teams);
        this.startDateParsed = startDate.toISOString().substring(0,10);
        this.endDateParsed = endDate.toISOString().substring(0,10);
        this.cdr.detectChanges();
      }
    )
  }

  public toggleGeneral() : void {
    this.showGeneral = true;
    this.showMembers = false;
    this.showTasks = false;
  }

  public toggleMembers() : void {
    this.showMembers = true;
    this.showGeneral = false;
    this.showTasks = false;
  }

  public toggleTasks() : void {
    this.showTasks = true;
    this.showGeneral = false;
    this.showMembers = false;
  }

  public openAddTeamModal() : void {
    const modalRef = this.modalService.open(
      AddTeamToProjectModalComponent,
      {backdrop : 'static', keyboard : true}
    );

    modalRef.componentInstance.projectId = this.rotue.snapshot.paramMap.get('id');
    modalRef.componentInstance.displayedTeams = this.teams;

    modalRef.componentInstance.teamAdded.subscribe(
      () => {
        this.toast.success("Team succesfully added to project.");
        this.getProject();
      }
    );

  }

  public addMembers() : void {
    const modalRef = this.modalService.open(
      AddEmployeesToProjectModalComponent,
      {backdrop : 'static', keyboard : true}
    );
    modalRef.componentInstance.projectId = this.projectId;

    modalRef.componentInstance.memberAdded.subscribe(
      () => {
        this.toast.success("Member succefully added.");
        this.getProject();
      }
    );
  }

  public handleEmployeeRemovedFromProject() {
    this.getProject();
  }

  public handleTeamRemovedFromProject() {
    this.getProject();
  }
}