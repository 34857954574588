import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Engagement } from '../../models/engagement';
import { Status } from '../../models/project-status';

@Component({
  selector: 'app-engagement-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './engagement-card.component.html',
  styleUrl: './engagement-card.component.css'
})
export class EngagementCardComponent implements OnInit{

  @Input() engagement! : Engagement;

  active : boolean = true;
  paused : boolean = false;
  scheduled : boolean = false;
  finished : boolean = false

  public positionName : string = 'Backend developer';
  public projectName : string = 'Projekat Europlazma Palacinka';
  public primarySkillName : string = 'Angular';
  public requiredSkillLevelName : string = 'Level 2';

  ngOnInit(): void {
    if(this.engagement.ProjectStatus === Status.Active){
      this.active = true;
      this.paused = false;
      this.scheduled = false;
      this.finished = false;
    } else if(this.engagement.ProjectStatus === Status.Finished){
      this.active = false;
      this.paused = false;
      this.scheduled = false;
      this.finished = true;
    } else if(this.engagement.ProjectStatus === Status.Paused){
      this.active = false;
      this.paused = true;
      this.scheduled = false;
      this.finished = false;
    } else if(this.engagement.ProjectStatus === Status.Scheduled){
      this.active = false;
      this.paused = false;
      this.scheduled = true;
      this.finished = false;
    }
  }
}
