<div class="flex flex-row gap-2 p-4 w-auto max-w-[400px] border-[1px] rounded-md hover:translate-y-[-5px] hover:shadow-xl 
                            items-center transition-[0.5s] cursor-pointer" (click)="function()">
    <div class="flex size-[80px] img-container rounded-sm border-[1px] border-primaryBlack/20">
        @if (hasPicture) {
        <img [src]="imageUrl" class="rounded-sm size-full" />
        } @else {
        <img src="../../../../assets/icons/AssetsIcon.png" class="rounded-full" />
        }
    </div>
    <div class="flex flex-col gap-2">
        <p class="text-primaryBlack font-medium text-[16px]">{{name}}</p>
        <div class="flex flex-col">
            @if (serialNumber) {
            <p class="text-primaryBlack font-light text-[14px]">Serial number: <span
                    class="font-semibold">{{serialNumber}}</span></p>
            } @else {
                <p class="text-white font-light text-[14px]">a<span
                    class="font-semibold"></span></p>
            }
            <p class="text-primaryBlack font-light text-[14px]">Requires approval:
                @if (permission) {
                <span class="font-semibold text-lightRed">YES</span>
                } @else {
                <span class="font-semibold text-lightGreen">NO</span>
                }
            </p>
            <p class="text-primaryBlack font-light text-[14px]">Number of available assets: <span
                    class="font-semibold">{{availableNumber}}</span></p>
        </div>
    </div>
</div>