import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmployeeService } from '../../services/employee.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-small-employee-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './small-employee-card.component.html',
  styleUrl: './small-employee-card.component.css'
})
export class SmallEmployeeCardComponent implements OnInit{

  @Input() EmployeeID : string = '';

  public employee : any;  
  public employeePictureLink : any;

  public FirstName : string = "";
  public LastName : string = "";
  public Role : string = "";
  public Gross : number = 0;

  constructor(
    private employeeService : EmployeeService,
    private sanitizer: DomSanitizer,
  ){}

  ngOnInit(): void {
    this.getEmployeePicture(this.EmployeeID);
    this.employeeService.getEmployeeById(this.EmployeeID).subscribe(
      (response : any) => {
        console.log("this is the small card response: ", response);
        this.FirstName = response.FirstName;
        this.LastName = response.LastName;
        this.Role = response.Role;
        this.Gross = response.Financial.Gross;
      }
    );
  }


  public getEmployeePicture(defaultPicture : any | undefined) {
    this.employeeService.getEmployeeImagePortal(this.EmployeeID).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    )  
  }
}
