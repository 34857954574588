import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { CompanyService } from './services/company.service';
import { BasicCompanyDTO } from './models/basicCompanyDTO';
import { ActivityLogBarComponent } from './components/activity-log-bar/activity-log-bar.component';
import { ActivityService } from './services/activity.service';
import { MatButtonModule } from '@angular/material/button';
import { ActivityLog } from './models/activityLog';
import { NgToastModule, ToasterPosition } from 'ng-angular-popup'
import { CustomThemeService } from './services/custom-theme.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, NavigationComponent, HttpClientModule, ActivityLogBarComponent, MatButtonModule, NgToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'frontend';

  ToasterPosition = ToasterPosition;

  public loggedIn : boolean = true;
  public user : any;
  public color : string | null = null;
  public domain : string = "";
  public basicCompanyData? : BasicCompanyDTO;
  public activityExists : boolean = false;
  public checkedIn : boolean = false;

  constructor(private authService : AuthService,
    private companyService : CompanyService,
    private route : ActivatedRoute,
    private _ngZone : NgZone,
    private activityService : ActivityService,
    private themeService : CustomThemeService
  ) {}

  ngOnInit(): void {
    const domainFromRoute = this.route.snapshot.paramMap.get('domain');


    // sessionStorage.setItem("companyColor","#8210b3");
    // this.color = sessionStorage.getItem("companyColor");

    this.themeService.applyThemeColor();
    if(domainFromRoute != null){
      this.domain = domainFromRoute;
      console.log(this.domain);
    }
    this._ngZone.run(
      () => {
        this.companyService.companyColor$.subscribe(
          color => {
            this.color = sessionStorage.getItem('companyColor');
          }
        )
        this.authService.authStatus$.subscribe(status => {
          this.loggedIn = status;
          if(this.loggedIn) {
            this.authService.getUser().subscribe(
              (response : any) => {
                this.user = response;
                //console.log("USERCINA APP: ", this.user);
                if(this.user.CheckedIn === -1){
                  //console.log("CHECKED IN: ", this.user.CheckedIn);
                  this.checkedIn = false;
                } else {
                  this.checkedIn = true;
                }
                this.activityExist();
              }
            )
          }
        });
      }
    )
  }


  // public fetchMyActivities() : void {
  //   this.activityService.getMyActivities().subscribe(
  //     (response : any) => {
  //       console.log("My activities: ", response);
  //       this.myActivities = response;
  //     },
  //     (error : HttpErrorResponse) => {
  //       console.log("Error while fetching my activities, ", error);
  //     }
  //   )
  // }
  
  public activityExist() : void {
    this.activityService.getActiveLog().subscribe(
      (response : ActivityLog) => {
        console.log("AKTIVAN LOG POSTOJI: ", response);
        this.activityExists = true;
      },
      (error : HttpErrorResponse) => {
        this.activityExists = false;
        console.log("Error while fetching activity log, ", error.message);
      }
    )
  }
}
