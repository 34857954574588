import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AssetStatus } from '../models/assetStatus';
import { AvailableAsset } from '../models/availableAsset';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  private apiServerUrl = environment.apiServerUrl;
  
  constructor(private http : HttpClient) { }

  public getAll() : Observable<any[]> {
    return this.http.get<any[]>(`${this.apiServerUrl}/asset/getAll`);
  }

  public getAssetHistory(assetId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/admin/find/assetHistory?assetID=${assetId}`)
  }

  public getAvailable() : Observable<any>{
    return this.http.get<AvailableAsset[]>(`${this.apiServerUrl}/asset/find/all/available`);
  }

  public getAssetHistoryForEmployee() : Observable<any>{
    const employeeId = sessionStorage.getItem('employeeId');
    return this.http.get<any>(`${this.apiServerUrl}/asset/client/find/for?employeeID=${employeeId}`);
  }

  // public getAssetImage(assetId : string) : Observable<string>{
  //   const companyId = sessionStorage.getItem("domain");
  //   return this.http.get<string>(`${this.apiServerUrl}/S3/generateS3Link/${assetId}/${companyId}`)
  // }

  public rentAsset(assetId : string, body : any) : Observable<any>{
    const employeeId = sessionStorage.getItem('employeeId')
    return this.http.post<any>(`${this.apiServerUrl}/asset/assignTo/employee?assetID=${assetId}&employeeID=${employeeId}`, body);
  }
  
  public getById(assetId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/get/by/id?assetID=${assetId}`);
  }

  public getEngagementsForEmployee() : Observable<any> {
    const employeeId = sessionStorage.getItem('employeeId');
    return this.http.get<any>(`${this.apiServerUrl}/employee/get/all/engagements/client/${employeeId}`);
  }

  public returnAsset(projectId : string, assetId : string) : Observable<any> {
    const employeeId = sessionStorage.getItem('employeeId');
    const body = {
      projectID : projectId
    };
    return this.http.post<any>(`${this.apiServerUrl}/asset/return?assetID=${assetId}&employeeID=${employeeId}`, body);
  }

  public getAssetImage(assetId : string) : Observable<string> {
    return this.http.get<any>(`${this.apiServerUrl}/S3/portal/generateS3Link/${assetId}`);
  }

}
