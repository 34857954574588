<div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
    <form [formGroup]="form" class="flex absolute top-0 left-0 items-center justify-center w-full h-full bg-primaryBlack/50">
        <div class="flex items-center w-[600px] justify-center bg-primaryBlack/30 font-poppins">
            <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-center justify-between">
                    <p class="text-[24px] font-semibold text-primaryBlack">Create new request</p> 
                    <button class="flex size-[30px] rounded-md bg-primaryBlack items-center justify-center 
                    hover:bg-red transition-[0.5s]"
                            (click)="modalService.close()">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                    </button>        
                </div>
                <div class="flex flex-row w-full items-start justify-center gap-4">
                    <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                        <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">REQUEST INFO</p>
                        <div class="flex flex-col w-full">
                            @if (form.get('StartDate')?.touched && form.get('StartDate')?.hasError('invalidDate')) {
                                <label for="StartDate" class="text-lightRed font-regular text-[12px]">Start date (Start date cannot be before tomorrow)</label>
                            } @else if (form.get('StartDate')?.touched && form.get('StartDate')?.invalid) {
                                <label for="StartDate" class="text-lightRed font-regular text-[12px]">Start date (Required)</label>
                            } @else {
                                <label for="StartDate" class="text-primaryBlack/70 font-regular text-[12px]">Start date</label>
                            }
                            <input formControlName="StartDate" type="date" [min]="today" id="StartDate" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                        </div>
                        
                        <div class="flex flex-col w-full">
                            @if (form.get('Message')?.touched && form.get('Message')?.invalid) {
                                <label for="Message" class="text-lightRed font-regular text-[12px]">Message (Required)</label>
                            } @else {
                                <label for="Message" class="text-primaryBlack/70 font-regular text-[12px]">Message</label>
                            }
                            <textarea formControlName="Message" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen min-h-[200px]" placeholder="Message"></textarea>
                        </div>
                        <div class="flex flex-col w-full">
                            @if (form.get('NumberOfDaysRequested')?.touched && form.get('NumberOfDaysRequested')?.invalid) {
                                <label for="NumberOfDaysRequested" class="text-lightRed font-regular text-[12px]">Number of days (Required)</label>
                            } @else {
                                <label for="NumberOfDaysRequested" class="text-primaryBlack/70 font-regular text-[12px]">Number of days</label>
                            }
                            <input formControlName="NumberOfDaysRequested" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Number of days"/>
                        </div>
                    </div>
                </div>
                <div class="flex w-full justify-end">
                    <button [disabled]="!form.valid" (click)="sendRequest()"
                            [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                            class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                        Send Request
                    </button>
                </div>
            </div>
        </div>
    </form>
    
</div>