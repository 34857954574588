import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/employee.service';
import { SmallEmployeeCardComponent } from '../../small-employee-card/small-employee-card.component';

@Component({
  selector: 'app-add-employees-to-project-modal',
  standalone: true,
  imports: [CommonModule, SmallEmployeeCardComponent],
  templateUrl: './add-employees-to-project-modal.component.html',
  styleUrl: './add-employees-to-project-modal.component.css'
})
export class AddEmployeesToProjectModalComponent implements OnInit{

  @Input() projectId : string = "";
  @Output() memberAdded = new EventEmitter<any>();

  public employees : any[] = [];
  public selected : boolean = false;
  public activeEmployeeId : string = "";
  public selectedEmployee : any;
  public project : any;

  constructor(
    public modalService : NgbActiveModal,
    private employeeService : EmployeeService
  ){}

  ngOnInit(): void {
    this.employeeService.getEmployees(this.projectId).subscribe(
      (response : any) => {
        //console.log("This is the modal response:", response);
        this.employees = response;
      }
    );
  }

  public setActive(employee: any): void {
    if(employee.EmployeeID) {
      this.activeEmployeeId = employee.EmployeeID;
    } else {
      this.activeEmployeeId = employee;
    }
    this.selectedEmployee = employee;
    this.selected = true;

    console.log("This is the selected employee", this.selectedEmployee);
  }

  public isActive(employeeId: string): boolean {
    return this.activeEmployeeId === employeeId;
  }

  public select() : void {
    this.employeeService.addEmployeeToProject(this.activeEmployeeId, this.projectId).subscribe(
      (response : any) => {
        this.memberAdded.emit();
        this.modalService.close();
      }
    )
  }

}
