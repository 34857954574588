import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/employee.service';
import { Engagement } from '../../../models/engagement';
import { HttpErrorResponse } from '@angular/common/http';
import { EngagementCardComponent } from "../../engagement-card/engagement-card.component";
import { ActivityService } from '../../../services/activity.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TeamsService } from '../../../services/teams.service';
import { TasksService } from '../../../services/tasks.service';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-start-activity-modal',
  standalone: true,
  imports: [CommonModule, EngagementCardComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './start-activity-modal.component.html',
  styleUrl: './start-activity-modal.component.css'
})
export class StartActivityModalComponent implements OnInit{

  @Output() activityStarted = new EventEmitter<void>();

  public form! : FormGroup;

  public engagements : Engagement[] = [];
  public tasks : any;
  public selectedProjectId : string = ''
  public selectedTaskId : string = ''


  constructor(public modalService : NgbActiveModal,
    private employeeService : EmployeeService,
    private activityService : ActivityService,
    private fb : FormBuilder,
    private teamsService : TeamsService,
    private tasksService : TasksService,
    private companyService : CompanyService
  ){}

  ngOnInit(): void {
    this.initializeForm();
    this.getEngagements();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      Title : ['', Validators.required],
    });
  }

  public startActivity() : void {
    if(this.form.valid){
      const formData = this.form.value;
      this.activityService.startActivity(this.selectedProjectId, formData.Title, this.selectedTaskId).subscribe(
        (response : any) => {
          console.log("Activity started...");
          this.activityStarted.emit();
          this.modalService.close();
        }
      )
    }
  }


  public isActive(projectId : string) : boolean {
    return this.selectedProjectId === projectId;
  }

  public setActive(projectId : string) : void {
    if(this.selectedProjectId === projectId){
      this.selectedProjectId = '';
    } else {
      this.selectedProjectId = projectId;
    }
    this.getTasks();
  }

  public isActiveTask(taskId : string) : boolean {
    return this.selectedTaskId === taskId;
  }

  public setActiveTask(taskId : string) : void {
    if(this.selectedTaskId === taskId){
      this.selectedTaskId = '';
    } else {
      this.selectedTaskId = taskId;
    }
  }

  public getEngagements() {
    this.employeeService.getClientEngagements().subscribe(
      (response : Engagement[]) => {
        this.engagements = response;
        console.log("Engagements: ", this.engagements);
      }
    )
  }

  public getTasks() {
    const assignee : string | null = sessionStorage.getItem('employeeId')
    this.companyService.getIdByDomain().subscribe(
      (response : string) => {
        this.tasksService.getMyTasksForProject(this.selectedProjectId, response, assignee).subscribe(
          (response : any) => {
            this.tasks = response;
            console.log("Employee tasks: ", this.tasks);
          }
        )
      }
    )
  }

}
