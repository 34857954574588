<div [ngClass]="{'bg-active' : active,
                'bg-scheduled' : scheduled,
                'bg-paused' : paused,
                'bg-finished' : finished}"
        class="flex flex-col p-4 rounded-md max-w-[400px] min-w-[250px] h-auto w-auto relative
            hover:shadow-md transition-[0.5s] gap-4 relative border-[1px] border-primaryBlack/40 hover:border-lightGreen">
    <div class="flex flex-col w-full justify-start gap-2">
        <p class="text-[16px] font-medium text-primaryBlack">{{engagement.ProjectName}}</p>
        <div class="flex flex-col w-full">
            <p class="text-[12px] font-light text-primaryBlack">Team: <span class="font-medium">{{engagement.TeamName}}</span></p>
        </div>
    </div>
</div>