<div class="flex flex-col font-poppins w-full h-full items-center justify-center gap-4">
    <!-- @if (isLoading && !linkSent) {
        <svg aria-hidden="true" class="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    } -->
    <ng-container *ngIf="isLoading && !linkSent">
        <svg aria-hidden="true" class="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </ng-container>

    <form [formGroup]="form" *ngIf="!isLoading && !linkSent" class="flex w-full h-full items-center justify-center">
        <div class="flex flex-col w-full items-center justify-center gap-4">
            <div class="flex flex-row rounded-full size-[100px] md:size-[200px] border-[1px] items-center img-container justify-center">
                <img [src]="imageLink" class="size-full rounded-full"/>
            </div>
            <div class="flex flex-row">
                <p [ngStyle]="{'color' : basicCompanyData?.colorHash, '-webkit-text-stroke' : lightText ? '0.5px #282B28' : 'px #F5F6FA'}"
                class="font-bold text-lightGreen text-[48px]">{{basicCompanyData?.name}}</p>
            </div>
            <div class="flex flex-col w-full items-center justify-center">
                <p class="text-[16px] md:text-[24px] text-primaryBlack font-medium">Sign in to your account</p>
                <p class="text-[12px] md:text-[16px] text-primaryBlack font-light">Enter your email to sign in to your account</p>
            </div>
            <div class="flex flex-col w-[50%] items-center justify-center gap-4">
                <div class="flex flex-col w-full">
                    <label *ngIf="form?.get('email')?.touched && form?.get('email')?.invalid" 
                            for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                    <label *ngIf="!form?.get('email')?.touched || !form?.get('email')?.invalid" 
                            for="email" class="text-primaryBlack/70 font-regular text-[12px]">Email address</label>
                    <input formControlName="email" 
                            class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" 
                            placeholder="email@domain.com" type="email"/>
                </div>

                <div class="flex w-full bg-lightGreen items-center justify-center">
                    <button class="flex flex-row w-full items-center justify-center gap-2 p-2 rounded-md" [ngStyle]="{'background' : basicCompanyData?.colorHash, 'border' : lightText ? '0.5px solid #282B28' : '0.5px solid  #F5F6FA'}"
                            (click)="sendMagicLink()">
                        <img src="../../../assets/icons/LinkIcon.png" class="size-[18px] md:size-[24px]" [ngClass]="{'nav_menu_icon' : lightText}"/>
                        <p class="text-[14px] md:text-[18px] font-medium font-poppins" [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28'}">Sign in</p>
                    </button>            
                </div>
          </div>
        </div>
    </form>

    <ng-container *ngIf="linkSent">
        <div [ngStyle]="{'background' : basicCompanyData?.colorHash}" 
            class="size-[100px] rounded-full items-center justify-center p-4">
            <img src="../../../assets/icons/MailOpenedIcon.png" class="size-full nav_menu_icon"/>
        </div>
        <p class="text-[24px] font-semibold text-primaryBlack">An email is on its way!</p>
        <div class="flex flex-col w-[50%] items-center justify-center gap-2">
            <p class="text-[16px] font-light text-primaryBlack">We sent an email to <span class="font-medium">{{email}}</span></p>
            <p class="text-[16px] font-light text-primaryBlack text-center word-break-all">If this email address has an account on the <span class="font-medium" [ngStyle]="{'color' : basicCompanyData?.colorHash}">firme-generale.cloud</span>, you'll find a magic link that will sign you into the application.</p>
        </div>
        <a href="https://mail.google.com/">
            <p class="text-[18px] font-semibold text-primaryBlack hover:text-lightGreen underline transition-[0.5s]">Check your inbox</p>
        </a>
    </ng-container>
</div>
