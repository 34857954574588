<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Notifications</p> 
    </div>
    <div class="flex flex-row w-full items-center bg-lightGreen/30 rounded-md p-4 gap-4">
        <img src="../../../assets/icons/NotificationIcon.png" class="size-[30px]"/>
        <div class="flex flex-col w-full">
            <p class="text-[24px] font-semibold text-primaryBlack">General</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum debitis assumenda neque tempore reiciendis maiores nesciunt. Maiores ut, ipsam voluptatibus quis suscipit mollitia laboriosam, et odio ipsa asperiores, deserunt doloribus!</p>
        </div>
    </div>
</div>