import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityDetailsComponent } from '../../components/modals/activity-details/activity-details.component';
import { ActivityService } from '../../services/activity.service';
import { ActivityLog } from '../../models/activityLog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { EmployeeService } from '../../services/employee.service';
import { Engagement } from '../../models/engagement';
import { filter, flatMap, map, take } from 'rxjs';

@Component({
  selector: 'app-activities',
  standalone: true,
  imports: [],
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.css'
})
export class ActivitiesComponent implements OnInit{
 
  public activities : ActivityLog[] = [];
  public fileterdActivities? : any[];
  public engagements : any[] = [];

  constructor(private modalService : NgbModal,
    private activityService : ActivityService,
    private projectService : ProjectService,
    private employeeService : EmployeeService
  ){}

  ngOnInit(): void {
    this.getProjects();
  }

  public viewDetails(activity : ActivityLog) : void {
    const modalRef = this.modalService.open(
      ActivityDetailsComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.activity = activity;
  }

  public fetchMyActivities() : void {
    this.activityService.getMyActivities().subscribe(
      (response : ActivityLog[]) => {
        this.activities = response;
        console.log("My activities: ", this.activities);
        this.parseActivities();
      }
    )
  }

  public parseActivities(): void {
    this.fileterdActivities = this.activities.map((activity) => {
      const project = this.engagements.find(
        (engagement) => engagement.ProjectID === activity.ProjectID 
      );
        
      return {
        ...activity,
        startDateParsed: this.parseStartDate(activity.Start),
        durationParsed: activity.Duration / (60 * 60 * 1000),
        endDateParsed: this.parseEndDate(activity.End),
        project: project 
      };
    });
  
    console.log("FILTERED:", this.fileterdActivities);
  }

  public parseStartDate(startDate : number) : string {
    const newStartDate = new Date(startDate);
    const startDateParsed = newStartDate.toISOString().substring(0, 19);
    const split = startDateParsed.split('T');
    return split[0] + ' ' + split[1];  
  }

  public parseEndDate(endDate : number) : string {
    const newEndDate = new Date(endDate);
    const endDateParsed = newEndDate.toISOString().substring(0, 19);
    const split = endDateParsed.split('T');
    return split[0] + ' ' + split[1];
  }

  public getProjects() : void {
    const employeeId = sessionStorage.getItem("employeeId");
    if(employeeId) {
      this.employeeService.getClientEngagements().subscribe(
        (response : any) => {
          console.log("PROJECT NAMES: ", response);
          this.engagements = response;
          this.fetchMyActivities();
        }
      );
    }
  }
}
