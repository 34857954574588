<!-- <div class="flex flex-col w-[240px] h-[215px] border-[1px] rounded-md gap-2 items-center justify-center hover:border-lightGreen
            font-poppins relative cursor-pointer transition-[0.5s] border-primaryBlack/20 rounded-md">
    <div class="flex flex-row w-auto absolute top-2 right-5 items-center justify-center">
        <img src={{icon}} class="size-[15px]"/>
        <p [ngClass]="{'text-lightGreen ml-2' : approved,
                        'text-lightRed ml-2' : denied,
                        'text-lightYellow ml-2' : pending}"
            class="text-[14px] font-regular">{{status}}</p>
    </div>
    <div class="flex flex-row w-full items-center justify-center">
        <p class="text-[24px] font-bold text-lightGreen">F</p>
        <p class="text-[24px] font-regular text-primaryBlack">G</p>
    </div>
    <div class="flex flex-col items-center justify-center w-full">
        <div class="text-center word-break-all w-full px-2">
            <h1 class="text-[20px] font-medium text-balance">{{parsedDate}}</h1>
        </div>
    </div>
    <div class="flex flex-row w-full items-center justify-center">
        <img src="../../../assets/icons/DateIcon.png" class="size-[18px]"/>
        <p class="font-medium text-[14px] ml-2">{{parsedDate}}</p>
    </div>
</div> -->

<div class="flex flex-col p-4 rounded-md border-[1px] max-w-[400px] min-w-[250px] h-auto w-auto relative transition-[0.5s] gap-4 cursor-pointer"
    [ngClass]="{'border-lightGreen hover:bg-lightGreen/20' : approved,
                'border-lightRed hover:bg-lightRed/20' : denied,
                'border-lightYellow hover:bg-lightYellow/20' : pending}">
    <div class="flex flex-row w-full h-auto items-start justify-between">
        <img src="../../../assets/icons/PositionIcon.png" class="size-[40px]"/>
        <div class="flex flex-row w-auto absolute top-2 right-5 items-center justify-center gap-2">
            <img src={{icon}} 
                [ngClass]="{'icon_green' : approved,
                            'icon_red' : denied,
                            'icon_yellow' : pending}"
                class="size-[15px]"/>
            <p [ngClass]="{'text-lightGreen' : approved,
                            'text-lightRed' : denied,
                            'text-lightYellow' : pending}"
                class="text-[14px] font-regular">{{status}}</p>
        </div>
    </div>
    <div class="flex flex-col w-full justify-start gap-2">
        <p class="text-[16px] font-medium text-primaryBlack">{{parsedDate}}</p>
        <div class="flex flex-col w-full">
            <p class="text-[12px] font-light text-primaryBlack">Number of days requested: <span class="font-medium">{{daysParsed}}</span></p>
        </div>
    </div>
</div>