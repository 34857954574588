import { HttpErrorResponse, HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';

export const authInterceptor : HttpInterceptorFn = (req, next) => {
  console.log(">>>Intercepting - authorization....")
  console.log("Request: " + req.url);

  const authService = inject(AuthService);
  const toastService = inject(NgToastService);

  const token = sessionStorage.getItem('token');
  const companyDomain = sessionStorage.getItem('domain');

  const excludeFromIntercepting = [`/magicLink?UUID=`];

  let authReq = req.clone();

  if(req.url.includes(excludeFromIntercepting[0])) {
    const newReq = req.clone({
      withCredentials : true
    });
    return next(newReq);
  }

  if(token === null && companyDomain !== null) {
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders : {
        'companyDomain' : `${companyDomain}`
      }
    });
  }

  if(token !== null && companyDomain === null) {
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders : {
        'Authorization' : `Bearer ${token}`
      }
    });
  }

  if(token !== null && companyDomain !== null){
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders : {
        'Authorization' : `Bearer ${token}`,
        'companyDomain' : `${companyDomain}`
      }
    });
  }

  // return next(authReq).pipe(
  //   catchError((error : HttpErrorResponse) => {
  //     if(error.status === 401) {
  //       toastService.warning("Session has expired!", "Warning!", 5000);
  //       authService.$refreshTokenSubject.next(true); 
  //     }
  //     return throwError((error : any) => {
  //       // toastService.info("Unable to refresh session! You have been logged out", "Info!", 5000);
        // authService.logOut();
  //     })
  //   })
  // );
  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        if (!authService.isRefreshingToken) {
          authService.isRefreshingToken = true;
          // toastService.warning("Session has expired!", "Warning!", 5000);

          return authService.refreshToken().pipe(
            switchMap(() => {
              const newToken = sessionStorage.getItem('token');
              const newAuthReq = req.clone({
                setHeaders: {
                  'Authorization': `Bearer ${newToken}`,
                  'companyDomain': `${companyDomain}`
                }
              });
              return next(newAuthReq);
            }),
            catchError(refreshError => {
              authService.logOut();
              return throwError(() => refreshError);
            }),
            finalize(() => {
              authService.isRefreshingToken = false; 
            })
          );
        } else {
          return authService.tokenRefreshed$.pipe(
            switchMap(() => next(authReq))
          );
        }
      }

      return throwError(() => error);
    })
  );
}