import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
// import { NgToastService } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonComponent } from '../../components/button/button.component';
import { AssetsService } from '../../services/assets.service';
import { RentAssetModalComponent } from '../../components/modals/rent-asset-modal/rent-asset-modal.component';
import { AssetHistory, AvailableAsset, DisplayAvailableAsset } from '../../models/availableAsset';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../../services/auth.service';
import { Asset } from '../../models/asset';
import { EquipmentCardComponent } from "../../components/equipment-card/equipment-card.component";
import { EquipmentHistoryCardComponent } from "../../components/equipment-history-card/equipment-history-card.component";

interface AssetDisplay {
  availableAsset: AvailableAsset;
  availableAssetCount: AvailableAsset;
}

@Component({
  selector: 'app-employee-assets',
  standalone: true,
  imports: [ButtonComponent, EquipmentCardComponent, EquipmentHistoryCardComponent],
  templateUrl: './employee-assets.component.html',
  styleUrl: './employee-assets.component.css'
})
export class EmployeeAssetsComponent implements OnInit {

  public availableAssets: DisplayAvailableAsset[] = [];
  public assetHistory: AssetHistory[] = [];
  public assetDisplay: AssetDisplay[] = [];

  public assetPictureLink: any;
  public hasPicture: boolean = true;

  constructor(private assetsService: AssetsService,
    private modalService: NgbModal,
    private toast: NgToastService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getAvailableAssets();
    this.getAssetHistoryForEmployee();
    //console.log("This is asset history", this.assetHistory);
  }

  public getAvailableAssets() {
    this.assetsService.getAvailable().subscribe(
      (response: DisplayAvailableAsset[]) => {
        this.availableAssets = response;
        this.cdr.detectChanges();
        ////console.log("These are available assets: ", this.availableAssets);
        //this.getImagesForAssetsAvailable();
      }
    )
  }

  // public getAssetImage(asset : any): void {
  //   const company = sessionStorage.getItem("company");
  //   let param;
  //   let companyId;

  //   if (asset.defaultPicture) {
  //     param = "defaultasset";
  //     companyId = "firmegeneralepictures";
  //   } else {
  //     param = asset.ID;
  //     companyId = company;
  //   }

  //   this.assetService.getAssetImage(param, companyId).subscribe(
  //     (response: string) => {
  //       this.assetPictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.log("Error while getting S3 image link, ", error.message);
  //     }
  //   );
  // }
  public callRentAsset(selectedAsset : Asset) : () => void {
    return () => this.rentAsset(selectedAsset);
  }

  public rentAsset(selectedAsset: Asset) {
    const modalRef = this.modalService.open(
      RentAssetModalComponent,
      { backdrop: 'static', keyboard: true }
    );

    modalRef.componentInstance.selectedAsset = selectedAsset;

    modalRef.componentInstance.assetRented.subscribe(
      (res: any) => {
        this.getAvailableAssets();
        this.getAssetHistoryForEmployee();
        this.toast.success("Asset successfully rented!", "Success!", 3000);
      },
      (error: HttpErrorResponse) => {
        console.error("Here is the full error object: ", error);
        if (error.error.message === "You already sent request for this asset") {
          this.toast.danger("Booking request has already been sent.");
        } else {
          this.toast.danger("An error has occured while trying to book an asset.");
          console.log("An error has occured while trying to book an asset: ", error);
        }
      }
    );
  }

  public getAssetHistoryForEmployee() {
    //console.log("Thios dapokdkaspdkaspkaspld;m,a;lksmd;alksmd");
    this.assetsService.getAssetHistoryForEmployee().subscribe(
      (response: any) => {
        if (response) {
          // console.log("This is response history for employee: ", response);
          // this.assetHistory = response.map((asset: { StartDate: number; }) => ({
          //   ...asset, 
          //   dateParsed : this.getDate(asset.StartDate)
          // }));
          this.assetHistory = response;
          console.log(response);
          //this.getImagesForAssetHistory();

          console.log("Ovo je asset history: ",this.assetHistory);
        }
      }
    );
  }

  // public getDate(date: number): string {
  //   const startDate = new Date(date);
  //   const startDateParsed = startDate.toISOString().substring(0, 10);
  //   //console.log(startDateParsed)
  //   return startDateParsed;
  // }

  public callReturnAsset(projectId: string, assetId: string): () => void {
    return () => this.returnAsset(projectId, assetId);
  }

  public returnAsset(projectId: string, assetId: string): void {
    this.assetsService.returnAsset(projectId, assetId).subscribe(
      (response: any) => {
        console.log("Succesfully returned asset: ", response);
        this.toast.success("An email has been sent succesfully");
        this.getAvailableAssets();
        this.getAssetHistoryForEmployee();
        console.log("Ovo je asset id ", assetId);
        const slika = this.assetsService.getAssetImage(assetId).subscribe(
          (response: any) => {
            console.log("Ovo je slika: ", response);
          },
          (error: HttpErrorResponse) => {
            console.log("Ovo je error koji je nastao: ", error);
          }
        );
      },
      (error: HttpErrorResponse) => {
        console.log("Error while trying to return asset: ", error);
      }
    );
    console.log(this.assetHistory);
  }

  // public getAssetImages(assetId: string) {
  //   this.assetsService.getAssetImage(assetId).subscribe(
  //     (response: any) => {
  //       console.log("This is asset image response: ", response);
  //     },
  //     (error : HttpErrorResponse) => {
  //       console.log("Error has occured while getting image: ", error);
  //     }
  //   );
  // }

  // public getImagesForAssetHistory() { 
  //   this.assetHistory.forEach(asset => {
  //     this.assetsService.getAssetImage(asset.assetID).subscribe(
  //       (response : string) => {
  //         asset.assetImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
  //       }
  //     )
  //   });
  // }

  // public getImagesForAssetsAvailable() {
  //   this.availableAssets.forEach(asset => {
  //     //console.log(asset);
  //     this.assetsService.getAssetImage(asset.Asset.ID).subscribe(
  //       (response : string) => {
  //         asset.Asset.assetImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
  //       }
  //     )
  //   });
  // }
}
