import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient) { }

  public getMyTasksForProject(projectId : string, companyId : string, assigneeId : string | null) : Observable<any> {
    const token = sessionStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization' : `Bearer ${token}`,
      'companyID' : `${companyId}`
    })
    if(assigneeId) {
      return this.http.get(`${this.apiServerUrl}/task/getOnProject?ProjectID=${projectId}&AssigneeID=${assigneeId}`, {headers : headers});
    } else {
      return this.http.get(`${this.apiServerUrl}/task/getOnProject?ProjectID=${projectId}`, {headers : headers});
    }
  }

  public getMyTasks(companyId : string) : Observable<any> {
    const token = sessionStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization' : `Bearer ${token}`,
      'companyID' : `${companyId}`
    })
    return this.http.get(`${this.apiServerUrl}/task/getMyTasks`, {headers : headers});
  }

  public updateStatus(taskId : string, status : string, companyId : string) : Observable<any>{
    const token = sessionStorage.getItem('token')
    const headers = new HttpHeaders({
      'Authorization' : `Bearer ${token}`,
      'companyID' : `${companyId}`
    })
    return this.http.patch(`${this.apiServerUrl}/task/update?id=${taskId}&Status=${status}`, {}, {headers : headers});
  }
}
