<div class="flex flex-row w-full gap-4 font-poppins">
    <div class="flex flex-col items-center justify-between w-[50%] bg-white p-4 gap-4 rounded-md">
        <div class="flex flex-row w-full justify-between items-center">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">PERSONAL INFO</p>
        </div>
        <div class="flex flex-row h-auto w-full rounded-xl items-center">
            <div class="flex size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20 relative">
                @if(hasPicture) {
                    <img [src]="employeePictureLink" class="rounded-full size-full"/>
                } @else {
                    <img src="../../../assets/icons/UserIcon.png" class="rounded-full size-full"/>
                }                
            </div>
            <div class="flex flex-col items-start ml-4 gap-1">
                <div class="flex flex-col w-full items-start">
                    <p class="text-[28px] text-primaryBlack font-semibold">{{firstName}} {{lastName}}</p>
                </div>
                <div class="flex flex-row  items-center">
                    <img src="../../../assets/icons/MailIcon.png" class="size-[18px]">
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">{{email}}</p>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../../../assets/icons/PhoneIcon.png" class="size-[18px]">
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">{{emergencyContact}}</p>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../../../assets/icons/DateIcon.png" class="size-[18px]">
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">{{dateToDisplay}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-[50%] h-auto p-4 rounded-xl bg-white items-start">
        <div class="flex flex-row w-full items-center justify-between justify-center">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">BIO</p>
        </div>
        <p class="text-[12px] font-regular mt-4">{{bio}}</p>
    </div>
</div>