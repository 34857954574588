import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-equipment-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './equipment-card.component.html',
  styleUrl: './equipment-card.component.css'
})
export class EquipmentCardComponent implements OnInit{

  @Input() assetId : string = "";
  @Input() function!: () => void; 
  @Input() serialNumber : string = "";
  @Input() name : string = "";
  @Input() permission : boolean = false;
  @Input() availableNumber : number = 0;

  public imageUrl : any;
  public hasPicture : boolean = false;

  constructor(
    private assetService : AssetsService,
    private sanitizer : DomSanitizer
  ){}

  ngOnInit(): void {
    this.getImageForAsset();
  }

  public onFunction() : void {
    this.function();
  }

  public getImageForAsset(){
    this.assetService.getAssetImage(this.assetId).subscribe(
      (response : string) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
        this.hasPicture = true;
      }
    );
  }

}
