<div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
    <div class="flex items-center w-[1000px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Start new activity</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <form [formGroup]="form">
                <div class="flex flex-col gap-2">
                    <label for="email" class="text-primaryBlack font-regular text-[12px]">Activity log title</label>
                    <input formControlName="Title" class="w-[50%] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" 
                            placeholder="Title" type="text"/>
                </div> 
            </form>
            <div class="flex flex-col w-full max-h-[400px] overflow-y-auto gap-4">
                <p class="text-[14px] font-light text-primaryBlack/60">Select the project for which you want to get tasks.</p>
                <div class="flex flex-row flex-wrap justify-evenly">
                    @for (engagement of engagements; track $index) {
                        <div class="flex flex-col p-4 rounded-md  max-w-[400px] min-w-[250px] h-auto w-auto relative cursor-pointer 
                                    hover:translate-y-[-5px] hover:shadow-md transition-[0.5s] gap-4 relative border-[1px] border-primaryBlack/40 hover:border-lightGreen"
                            [ngClass]="{'bg-lightGreen/30' : isActive(engagement.ProjectID)}"
                            (click)="setActive(engagement.ProjectID)">
                            <div class="flex flex-col w-full justify-start">
                                <p class="text-[16px] font-medium text-primaryBlack">{{engagement.ProjectName}}</p>
                                <!-- <div class="flex flex-col w-full">
                                    <p class="text-[12px] font-light text-primaryBlack">Team: <span class="font-medium">{{engagement.TeamName}}</span></p>
                                </div> -->
                            </div>
                        </div>                        
                    }
                </div> 
            </div>

            <div class="flex flex-col w-full max-h-[400px] overflow-y-auto gap-4">
                <p class="text-[14px] font-light text-primaryBlack/60">Select the task for which you want to start the activity.</p>
                <div class="flex flex-row flex-wrap justify-evenly">
                    @for (task of tasks; track $index) {
                        <div class="flex flex-col p-4 rounded-md  max-w-[400px] min-w-[250px] h-auto w-auto relative cursor-pointer 
                                    hover:translate-y-[-5px] hover:shadow-md transition-[0.5s] gap-4 relative border-[1px] border-primaryBlack/40 hover:border-lightGreen"
                            [ngClass]="{'bg-lightGreen/30' : isActiveTask(task.ID)}"
                            (click)="setActiveTask(task.ID)">
                            <div class="flex flex-col w-full justify-start">
                                <p class="text-[16px] font-medium text-primaryBlack">{{task.Description}}</p>
                                <!-- <div class="flex flex-col w-full">
                                    <p class="text-[12px] font-light text-primaryBlack">Team: <span class="font-medium">{{engagement.TeamName}}</span></p>
                                </div> -->
                            </div>
                        </div>                        
                    }
                </div> 
            </div>
            <div class="flex w-full justify-end">
                <button (click)="startActivity()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': selectedProjectId === '', 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': selectedProjectId !== ''}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px] w-[220px]">
                    Start
                </button>
            </div>
        </div>
    </div>
</div>