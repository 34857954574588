import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestStatus } from '../../models/request-status';

@Component({
  selector: 'app-request-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './request-card.component.html',
  styleUrl: './request-card.component.css'
})
export class RequestCardComponent implements OnInit{
  @Input() numberOfDaysRequested : number = 0;
  @Input() status : RequestStatus = RequestStatus.Pending;
  @Input() date : number = 0;
  @Input() width : String = "240px";

  public parsedDate : string = '';
  public daysParsed : number = 0;
  public icon : String = '';

  approved : boolean = true;
  denied : boolean = false;
  pending : boolean = false;

  ngOnInit(): void {
    const startDate = new Date(this.date);
    this.parsedDate = startDate.toISOString().substring(0, 10);

    this.daysParsed = this.numberOfDaysRequested / 24 / 60 / 60 / 1000

    if(this.status === RequestStatus.Approved){
      this.icon = "../../../assets/icons/ApprovedIcon.png"
      this.approved = true;
      this.denied = false;
      this.pending = false;
    } else if(this.status === RequestStatus.Denied){
      this.icon = "../../../assets/icons/DeniedIcon.png"
      this.approved = false;
      this.denied = true;
      this.pending = false;
    } else if(this.status === RequestStatus.Pending){
      this.icon = "../../../assets/icons/ScheduledIcon.png"
      this.approved = false;
      this.denied = false;
      this.pending = true;
    }
  }

}
