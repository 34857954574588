<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Projects</p> 
    </div>
    <div class="flex flex-col w-full h-auto p-4 gap-4 bg-primaryWhite rounded-md">
        <div class="flex flex-col w-full gap-2">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide uppercase">Managed projects</p>
        </div>        
        <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            @for (project of projects; track $index) {
                <div [ngClass]="{'bg-active' : project.ProjectStatus === 'Active',
                                'bg-scheduled' : project.ProjectStatus === 'Scheduled',
                                'bg-finished' : project.ProjectStatus === 'Finished',
                                'bg-paused' : project.ProjectStatus === 'Paused'}" 
                    class="flex flex-col size-[300px] items-center justify-center p-4 rounded-md cursor-pointer hover:translate-y-[-5px] transition-[0.5s]
                            hover:shadow-xl gap-4"
                    (click)="viewProjectDetails(project)">
                    <div class="flex flex-col items-center justify-center w-full">
                        <div class="text-center word-break-all w-full px-2">
                            <p class="sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] 3xl:text-[34px] 4xl:text-[44px] font-semibold text-balance">{{project.Name}}</p>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <div class="flex flex-row w-full items-center justify-center">
                            <img src="../../../assets/icons/DateIcon.png" class="sm:size-[10px] md:size-[12px] lg:size-[12px] xl:size-[14px] 2xl:size-[16px] 3xl:size-[26px]"/>
                            <p class="font-medium ml-2">{{project.dateParsed}}</p>
                        </div>
                        <div class="flex flex-row w-full items-center justify-center">
                            <p class="font-light">Status: </p>
                            <p class="font-semibold ml-2">{{project.ProjectStatus}}</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>