<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Vacancies</p>
        <app-button
            [primary]=false
            [secondary]=true
            width="auto"
            text="Request day off"
            icon="../../../assets/icons/PlusIcon.png"
            [function]="this.openRequestDayOffModal.bind(this)"
        /> 
    </div>
    <!-- <div>
        <button class="bg-lightGreen text-primaryWhite p-4" (click)="refresh()">
            REFRESH TOKEN
        </button>
    </div> -->
    <div class="flex flex-row w-full items-center justify-center">
        <button class="flex rounded-l-md border-[1px] w-full justify-center p-4 transition-[0.5s] text-primaryWhite hover:bg-lightGreen"
                [ngStyle]="{'background' : showPending ? color : '#032123',}"
                [ngClass]="{'opacity-50 hover:opacity-100' : !showPending}"
                (click)="toggleRequestListView()" [disabled]="showPending">
            Show pending requests
        </button>
        <button class="flex rounded-r-md border-[1px] w-full justify-center border-primaryBlack/50 p-4 opacity-50 hover:opacity-100 text-primaryWhite hover:bg-lightGreen transition-[0.5s]"
                [ngStyle]="{'background' : !showPending ? color : '#032123',}"
                [ngClass]="{'opacity-50 hover:opacity-100' : showPending}"
                (click)="toggleRequestListView()" [disabled]="!showPending">
            <p>Show answered requests</p>
        </button>
    </div>
    @if (showPending) {
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4 relative">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">PENDING REQUESTS</p>
            <div class="flex flex-row flex-wrap items-start justify-evenly w-full overflow-x-auto gap-4">
                @for (req of pendingRequests; track $index) {
                    <app-request-card
                        [status]="req.Status"
                        [date]="req.StartDate"
                        [numberOfDaysRequested]="req.NumberOfDaysRequested"
                    />
                }
            </div>
        </div>
    } @else {
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4 relative">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">ANSWERED REQUESTS</p>
            <div class="flex flex-row flex-wrap items-start justify-evenly w-full overflow-x-auto gap-4">
                @for (req of answeredRequests; track $index) {
                    <app-request-card
                        [status]="req.Status"
                        [date]="req.StartDate"
                        [numberOfDaysRequested]="req.NumberOfDaysRequested"
                    />
                }
            </div>
        </div>
    }
</div>