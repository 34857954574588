import { CommonModule } from '@angular/common';
import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamsService } from '../../../services/teams.service';
import { ColorTranslator } from 'colortranslator';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-add-team-to-project-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './add-team-to-project-modal.component.html',
  styleUrl: './add-team-to-project-modal.component.css'
})
export class AddTeamToProjectModalComponent implements OnInit{

  @Input() projectId : string = "";
  @Input() displayedTeams : any[] = [];
  @Output() teamAdded  = new EventEmitter<any>();

  public allTeams : any[] = []
  public teams : any[] = [];
  public selectedTeamId : string = "";

  constructor(
    public modalService : NgbActiveModal,
    private teamsService : TeamsService,
    private route : ActivatedRoute,
    private projectService : ProjectService
  ){}

  ngOnInit(): void {
    this.fetchTeams();
  }

  public fetchTeams() : void {
    this.teamsService.getAllTeams().subscribe(
      (response : any[]) => {
        this.teams = response.filter(team => 
          !this.displayedTeams.includes(team.ID)
        );
      }
    );
  }

  public getBadgeBackgroundColor(team : any) : string {
    const color_rgb = new ColorTranslator(team.ColorHash);
    const badgeRGBA = "rgba(" + color_rgb.R + ", " + color_rgb.G + ", " + color_rgb.B + ", 0.2)"

    return badgeRGBA;
  }

  public setActive(team : any) : void {
    this.selectedTeamId = team.ID;
  }

  public isActive(teamId: string): boolean {
    return this.selectedTeamId === teamId;
  }

  public addTeamToProject() : void {
    this.projectService.addTeamToProject(this.projectId,this.selectedTeamId).subscribe(
      (response : any) => {
        this.teamAdded.emit();
        this.modalService.close();
      }
    );
  }

}
