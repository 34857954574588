import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance',
  standalone: true,
  imports: [],
  templateUrl: './finance.component.html',
  styleUrl: './finance.component.css'
})
export class FinanceComponent implements OnInit{

  // private chart!: ApexCharts;

  @Input() netSalary : number = 2000;
  @Input() grossSalary : number = 4000;

  public netPerHour : number = 0;
  public netPerDay : number = 0;
  public netPerYear : number = 0;

  public grossPerHour : number = 0;
  public grossPerDay : number = 0;
  public grossPerYear : number = 0;


  ngOnInit(): void {
    // this.renderChart()
    this.calculateNetAndGross()
  }

  public calculateNetAndGross() : void {
    this.netPerHour = parseFloat((this.netSalary / 22 / 8).toFixed(1));
    this.netPerDay = parseFloat((this.netSalary/22).toFixed(1));
    this.netPerYear = this.netSalary*12

    this.grossPerHour = parseFloat((this.grossSalary/22/8).toFixed(1));
    this.grossPerDay = parseFloat((this.grossSalary/22).toFixed(1));
    this.grossPerYear = this.grossSalary*12
  }

  // public renderChart() : void {
  //   const options = {
  //     series: [
  //       {
  //         name: "Net salary",
  //         data: [1500, 1418, 1456, 1526, 1356, 1256, 1500, 1418, 1456, 1526, 1356, 1256],
  //         color: "#39b36a",
  //       },
  //       {
  //         name: "Gross salary",
  //         data: [1643, 1413, 1765, 1412, 1423, 1731, 1643, 1413, 1765, 1412, 1423, 1731],
  //         color: "#0A210F",
  //       },
  //     ],
  //     chart: {
  //       height: "100%",
  //       maxWidth: "100%",
  //       type: "area",
  //       fontFamily: "Inter, sans-serif",
  //       dropShadow: {
  //         enabled: false,
  //       },
  //       toolbar: {
  //         show: true,
  //       },
  //     },
  //     tooltip: {
  //       enabled: true,
  //       x: {
  //         show: true,
  //       },
  //     },
  //     legend: {
  //       show: true
  //     },
  //     fill: {
  //       type: "gradient",
  //       gradient: {
  //         opacityFrom: 0.55,
  //         opacityTo: 0,
  //         shade: "#39b36a",
  //         gradientToColors: ["#39b36a"],
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       width: 4,
  //     },
  //     grid: {
  //       show: false,
  //       strokeDashArray: 4,
  //       padding: {
  //         left: 2,
  //         right: 2,
  //         top: 0
  //       },
  //     },
  //     xaxis: {
  //       categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  //       labels: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //       },
  //       axisTicks: {
  //         show: true,
  //       },
  //     },
  //     yaxis: {
  //       show: true,
  //       labels: {
  //         formatter: function (value: string) {
  //           return '$' + value;
  //         }
  //       }
  //     },
  //   };

  //   this.chart = new ApexCharts(document.querySelector('#chart'), options);
  //   this.chart.render();
  // }
}
