import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public getAll(teamId : string) : Observable<any> {
    //return this.http.get<any>(`${this.apiServerUrl}/project/find/team/by/id/${teamId}`);
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/get/all/teams`);
  }

  public getEmployeeImage(employeeId : string) : Observable<string> {
    return this.http.get<any>(`${this.apiServerUrl}/S3/portal/generateS3Link/${employeeId}`);
  }

  public getAllTeams() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/get/all/teams`);
  }

  public getTeamById(teamId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/manager/get/team/ById/${teamId}`);
  }

  public removeFromProject(projectId : string, teamId : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/manager/fire/team?teamID=${teamId}&projectID=${projectId}`,{});
  }
}
