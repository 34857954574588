import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BasicCompanyDTO } from '../models/basicCompanyDTO';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyNameSubject = new BehaviorSubject<string | null>(null);
  public companyStatusSubject = new BehaviorSubject<boolean>(false);

  public companyColorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isColorLoaded());
  public companyColor$: Observable<boolean> = this.companyColorSubject.asObservable();

  public setCompanyName(name: string): void {
    this.companyNameSubject.next(name);
  }

  public setCompanyStatus(status: boolean): void {
    this.companyStatusSubject.next(status);
  }

  public isColorLoaded() {
    const color = sessionStorage.getItem('companyColor');
    return !!color;
  }

  private apiServerUrl = environment.apiServerUrl;
 
  constructor(private http: HttpClient) { 
    const companyName = sessionStorage.getItem("CompanyName")
    if(companyName) {
      this.companyNameSubject.next(companyName);
    } else {
      this.companyNameSubject.next("No company")
    }
  }

  public getCompany() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/company/findByID`);
  }

  public getBaseCompanyDetails(domain : string) : Observable<BasicCompanyDTO> {
    return this.http.get<BasicCompanyDTO>(`${this.apiServerUrl}/company/GetCompanyFrom/${domain}`)
  }

  public getIdByDomain() : Observable<any> {
    return this.http.get<string>(`${this.apiServerUrl}/employee/getCompanyByDomain`);
  }
}
