import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TeamsService } from '../../services/teams.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { ProjectService } from '../../services/project.service';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-employee-card',
  standalone: true,
  imports: [],
  templateUrl: './employee-card.component.html',
  styleUrl: './employee-card.component.css'
})
export class EmployeeCardComponent implements OnInit{

  @Input() employeeId : string = "";
  @Input() displayedName : string = "";
  @Output() employeeDeletedFromProject = new EventEmitter<any>();

  projectId: string | null = null;
  public employeePictureLink : any;
  public fullName : string = "";
  public role : string = "";

  constructor(
    private sanitizer : DomSanitizer,
    private teamsService : TeamsService,
    private modalService : NgbModal,
    private projectService : ProjectService,
    private employeeService : EmployeeService,
    private route : ActivatedRoute,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.getEmployeePicture();
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.employeeService.getEmployeeById(this.employeeId).subscribe(
      (response : any) => {
        this.fullName = response.FirstName + " " + response.LastName;
        this.role = response.Role;
      }
    );
  }

  public getEmployeePicture() {
    this.teamsService.getEmployeeImage(this.employeeId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    );
  }

  public deleteEmployeeFromProject(event : Event) : void {
    event.stopPropagation();

    const modalRef = this.modalService.open(
      WarningModalComponent,
      {backdrop : 'static', keyboard : true}
    );

    // TODO

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        if(this.employeeId && this.projectId) {
          this.projectService.deleteEmployeeFromProject(this.employeeId,this.projectId).subscribe(
            (response : any) => {
              console.log("Member succesfully deleted from projectd");
              this.employeeDeletedFromProject.emit();
              this.toast.success("Employee removed from project.");
            }
          );
        } else {
          console.warn("Project id or employee id not provided");
        }
      }
    );
  }
}
