import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }
          
  public employeeNavigation(pagePath : string){
    const email = sessionStorage.getItem('email');
    const code = sessionStorage.getItem('code');
    const domain = sessionStorage.getItem('domain')
    this.router.navigate([`${domain}/${pagePath}/${email}/${code}`]);
  }

  // public navigateToProfileEmployee() : void {
  //   this.router.navigate([':domain/profile']);
  // }
}
