import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.css'
})
export class ProjectsComponent implements OnInit{

  public projects : any;

  constructor(private projectService : ProjectService,
    private router : Router
  ){}

  ngOnInit(): void {
    this.getProjects();
  }

  public getProjects() : void {
    this.projectService.getManagersProjects().subscribe(
      (response : any) => {
        this.projects = response.map((project: any) => {
          const date = new Date(project.StartDate);
          const dateParsed = date.toISOString().substring(0,10);
          return {
            ...project,
            dateParsed
          }
        })
        console.log("these are projects :) ",this.projects);
      }
    )
  }

  public viewProjectDetails(project : any) : void {
    const email = sessionStorage.getItem('email');
    const code = sessionStorage.getItem('code');
    const domain = sessionStorage.getItem('domain')
    this.router.navigate([`${domain}/projects/${project.ID}/${email}/${code}`]);
  }

}
