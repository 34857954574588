<div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
    <div class="flex items-center w-[1000px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Activity details</p> 
                <button class="flex size-[30px] rounded-md bg-primaryBlack items-center justify-center 
                hover:bg-red transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <p class="text-primaryBlack font-bold text-[32px]">{{activity?.Title}}</p>
            <div class="flex flex-row w-full items-start gap-4">
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack font-light text-[16px]">Start date: <span class="font-medium">{{activity?.startDateParsed}}</span></p>
                    <p class="text-primaryBlack font-light text-[16px]">End date: <span class="font-medium">{{activity?.endDateParsed}}</span></p>
                    <p class="text-primaryBlack font-light text-[16px]">Duration: <span class="font-medium">{{activity?.durationParsed}} hours</span></p>
                </div>
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack font-light text-[16px]">Project: <span class="font-medium">{{activity?.project.ProjectName}}</span></p>
                    <p class="text-primaryBlack font-light text-[16px]">Modified duration: <span class="font-medium">{{activity?.modifiedDurationParsed
                    }} hours</span></p>
                </div>
            </div>
            <hr class="w-full text-primaryBlack/20">
            <div class="flex flex-col w-full gap-2">
                <p class="text-primaryBlack font-light text-[14px] uppercase tracking-widest">Description</p>
                <p class="text-primaryBlack font-medium text-[16px]">{{activity?.Description}}</p>
            </div>
            <hr class="w-full text-primaryBlack/20">
            <div class="flex flex-col w-full gap-2 max-h-[350px] overflow-y-auto">
                <p class="text-primaryBlack font-light text-[14px] uppercase tracking-widest">Breaks</p>
                <table class="w-full text-sm md:text-md lg:text-lg xl:text-lg text-left rtl:text-right border-[1px] border-primaryBlack/20">
                    <thead class="text-xs sm:text-sm md:text-md xl:text-md text-primaryBlack uppercase bg-lightGrey bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Start
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Duration
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (break of breaks; track $index) {
                            <tr class="bg-primaryWhite h-[45px] border-b items-center transition-[0.5s]">
                                <td scope="row" class="flex items-center px-6 py-4 text-gray-900  whitespace-nowrap dark:text-white gap-4 img-container">
                                    <div class="font-semibold">
                                        <p class="font-medium text-[18px] text-primaryBlack">{{break.parsedStartDate}}</p>
                                    </div>
                                </td>
                                <td class="px-6">
                                    <p class="font-medium text-[18px] text-primaryBlack">[xy] hours</p>
                                </td>
                            </tr>
                        }
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>