<!-- <div [ngStyle]="{'background': !loggedIn ? '#F5F6FA' :
                                user ? (user.Role === 'Employee' ? color : 'linear-gradient(to top left, #39b36a 20%, #0A210F 80%)') :'#F5F6FA'}" 
                                class="flex fex-row w-[100vw] h-[100vh] p-4 gap-4 overflow-y-auto item-start justify-center relative"> -->
<div [ngStyle]="{'background': loggedIn ? color : '#f5f6fa'}" 
  class="flex fex-row w-[100vw] h-[100vh] p-4 gap-4 overflow-y-auto item-start justify-center relative">
  @if(loggedIn){
    <div class="flex w-[15%] items-start">
      <app-navigation class="w-full h-full"/>
    </div>
  }
  <div class="flex flex-col w-full gap-4 overflow-y-hidden">
    @if (loggedIn) {
      <div class="flex flex-row w-full items-center p-4 rounded-xl bg-lightGrey">
        <app-activity-log-bar class="flex w-full"
          [activityExists]="activityExists"
          [checkedIn]="checkedIn"
        />
      </div>
    }
    <div [ngClass]="{'rounded-2xl h-full bg-lightGrey' : loggedIn}"  
        class="flex-1 grow flex-row w-full h-full items-center p-4 overflow-y-auto">
      <router-outlet/>
      <ng-toast [position]="ToasterPosition.TOP_RIGHT"/>
    </div>
  </div>
  <p class="text-primaryBlack/30 font-light text-[10px] absolute bottom-1 right-1">0.1.1-226</p>
</div>
