import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityLog } from '../models/activityLog';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public startActivity(projectId : string, title : string, taskId : string) : Observable<any> {
    const domain = sessionStorage.getItem('domain')
    return this.http.post(`${this.apiServerUrl}/employee/${domain}/startActivity?projectID=${projectId}&Title=${title}&taskID=${taskId}`, {}, {responseType : 'text' as 'json'});
  } 

  public getMyActivities() : Observable<ActivityLog[]> {
    const domain = sessionStorage.getItem('domain');
    return this.http.get<ActivityLog[]>(`${this.apiServerUrl}/employee/${domain}/getMyActivities`, {responseType : 'json'})
  }

  public endActivity(activityId : string, duration : number, description : string) : Observable<any> {
    const domain = sessionStorage.getItem('domain');
    return this.http.patch<any>(`${this.apiServerUrl}/employee/${domain}/stopActivity/${activityId}?Description=${description}&Duration=${duration}`, {}, {responseType : "text" as "json"});
  }

  public getActiveLog() : Observable<ActivityLog> {
    const domain = sessionStorage.getItem('domain');
    return this.http.get<ActivityLog>(`${this.apiServerUrl}/employee/${domain}/getActiveLog`, { responseType: 'json' });
  }

  public startPause() : Observable<any> {
    const domain = sessionStorage.getItem('domain');
    return this.http.patch<any>(`${this.apiServerUrl}/employee/${domain}/startPause`, {}, { responseType : 'text' as 'json'});
  }

  public resumeActivity() : Observable<any> {
    const domain = sessionStorage.getItem('domain');
    return this.http.patch<any>(`${this.apiServerUrl}/employee/${domain}/stopPause`, {}, { responseType : 'text' as  'json'});
  }

  public checkIn() : Observable<any> {
    const domain = sessionStorage.getItem('domain');
    return this.http.patch<any>(`${this.apiServerUrl}/employee/${domain}/checkIn`, { responseType : 'text' as 'json'});
  }

  public checkOut() : Observable<any> {
    const domain = sessionStorage.getItem('domain');
    return this.http.patch<any>(`${this.apiServerUrl}/employee/${domain}/checkOut`, { responseType : 'text' as 'json'});
  }
}
