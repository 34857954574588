import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgToastService } from 'ng-angular-popup';
import { tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { ButtonComponent } from '../../components/button/button.component';
import { RequestCardComponent } from '../../components/request-card/request-card.component';
import { RequestDayOffModalComponent } from '../../components/modals/request-day-off-modal/request-day-off-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vacancies',
  standalone: true,
  imports: [ButtonComponent, RequestCardComponent, CommonModule],
  templateUrl: './vacancies.component.html',
  styleUrl: './vacancies.component.css'
})
export class VacanciesComponent implements OnInit{

  public showPending : boolean = true;
  public allRequests : any[] = [];
  public pendingRequests : any[] = [];
  public answeredRequests : any[] = [];
  public color : string = '';

  constructor(private modalService : NgbModal,
    public authService : AuthService,
  ) {}

  ngOnInit(): void {
    this.fetchRequests();
    const color = sessionStorage.getItem('companyColor');

    if(color){
      this.color = color;
    }

  }

  public toggleRequestListView() {
    this.showPending = !this.showPending;
  }

  // public refresh() : void {
  //   this.authService.checkUserRefreshToken().subscribe(
  //     (res : any) => {
  //       console.log("REFRESUJ MAMU TI JEBEM")
  //     },
  //     (error : HttpErrorResponse) => {
  //       console.log("ERRORCINA: ", error);
  //     }
  //   )
  // }

  public fetchRequests(){
    this.pendingRequests = [];
    this.allRequests = [];
    this.answeredRequests = [];
    const domain = sessionStorage.getItem('domain');
    this.authService.getUser().subscribe(
      (response : any) => {
        this.allRequests = response.Requests;
        this.allRequests.map((req) => {
          if(req.Status === 'Pending') {
            this.pendingRequests.push(req);
          } else {
            this.answeredRequests.push(req);
          }
        });
      }
    )
  }

  public openRequestDayOffModal() : void {
    const modalRef = this.modalService.open(
      RequestDayOffModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.requestCreated.subscribe(
      () => {
        this.fetchRequests();
        // this.toast.success("Request created successfully! The emails have been sent to the authorities for processing.", "Success!", 3000);
      }
    )
  }

}
