import { Component, ElementRef, OnInit } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { NavigationService } from '../../services/navigation.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { CompanyService } from '../../services/company.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ColorTranslator, Harmony, Mix } from 'colortranslator'
import { DomSanitizer } from '@angular/platform-browser';
import { CustomThemeService } from '../../services/custom-theme.service';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [ButtonComponent, CommonModule, RouterModule, FormsModule],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css'
})
export class NavigationComponent implements OnInit{

  public dropdownOpen: boolean = false;

  //Employees
  public showProfile : boolean = false;
  public showNotifications : boolean = true;
  public showVacancies : boolean = false;
  public showEmployeeAssets : boolean = false;
  public showFinance : boolean = false;
  public showActivities : boolean = false;
  public showEngagements : boolean = false;
  public showProjects : boolean = false;
  public showTasks : boolean = false;

  public loggedUser : any;
  public isAdmin : boolean = false;
  public userLogged : boolean = true;
  public lightText : boolean = false;

  public fullName : string = '';
  public email : string = '';
  public userId : string = '';
  public company : any;
  public companyName : string = '';
  public code! : string;

  public imageLink : any;

  constructor(public navigationService: NavigationService,
    public authService : AuthService,
    public router : Router,
    private companyService : CompanyService,
    private sanitizr : DomSanitizer,
    private themeService: CustomThemeService
  ){}
  
  ngOnInit(): void {
    this.companyService.companyColor$.subscribe(
      colorLoaded => {
        const company = sessionStorage.getItem('companyName');
        if(company) {
          this.companyName = company;
        }
        
        this.getColors();

        const img = sessionStorage.getItem('companyLogo')
        if(img) {
          this.imageLink = this.sanitizr.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + img);
        }
      }
    )
    this.authService.authStatus$.subscribe(status => {
      this.userLogged = status;
      if (status) {
        this.loadUserInfo();
      }
    });
    //this.themeService.applyThemeColor();
  }
  
  private loadUserInfo() {
    const domain = sessionStorage.getItem('domain');
    this.authService.getUser().subscribe(
      user => {
        this.loggedUser = user;
        
        sessionStorage.setItem('Role', this.loggedUser.Role);
        sessionStorage.setItem('fullName', user.FirstName + ' ' + user.LastName);
        sessionStorage.setItem('email', user.Email);
  
        if (this.loggedUser.Role === "Admin") {
          this.isAdmin = true;
          sessionStorage.setItem('isAdmin', 'true');
        } else {
          sessionStorage.setItem('isAdmin', 'false');
        }
  
        this.fullName = user.FirstName + " " + user.LastName;
        this.email = user.Email;
        this.userId = user.ID || user.EmployeeID || "000000000000000000000000";
      }
    );
  }

  public getColors(){
    if(this.isAdmin) {
      this.lightText = true;
      console.log("DA LI JE ADMIN: ", this.isAdmin);
      console.log("DA LI JE LIGHT ZA ADMINA: ", this.lightText);
    } else {
      const companyColor = sessionStorage.getItem('companyColor');
      if(companyColor){
        const color_hsl = new ColorTranslator(companyColor);
        console.log("HSL Color L: ", color_hsl.L)
        
        if(color_hsl.L >= 50) {
          this.lightText = false 
        } else {
          this.lightText = true;
        }
      }
    }
  }

  public viewUserProfile() : void {
    const code = sessionStorage.getItem('code');
    this.showProfile = true;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("profile");
  }

  public viewNotifications() : void {
    this.showProfile = false;
    this.showNotifications = true;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("notifications");
  }

  public viewVacancies() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = true;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("vacancies");
  }

  public viewTasks() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = true;
    this.navigationService.employeeNavigation("tasks");
  }

  public viewEmployeeAssets() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = true;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("employeeAssets");
  }

  public viewFinance() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = true;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("finance");
  }

  public viewEngagements() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = true;
    this.showActivities = false;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("engagements");
  }

  public viewActivities() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = true;
    this.showProjects = false;
    this.showTasks = false;
    this.navigationService.employeeNavigation("activities");
  }

  public viewProjects() : void {
    this.showProfile = false;
    this.showNotifications = false;
    this.showVacancies = false;
    this.showEmployeeAssets = false;
    this.showFinance = false;
    this.showEngagements = false;
    this.showActivities = false;
    this.showProjects = true;
    this.showTasks = false;
    this.navigationService.employeeNavigation("projects");
  }

}
