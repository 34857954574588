import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {

  public themeColor?: string;
  public textColor?: string; 
  public disabledButtonColor?: string;
  public hoveredButtonColor?: string;
  public iconColor?: string;
  public disabledButtonTextColor? : string;
  public buttonBgColor? : string;

  constructor() {
    // console.log("Ovo je text color:", this.textColor);
    // console.log("Ovo je theme color: ", this.themeColor);
  }

  public applyThemeColor() {

    const color = sessionStorage.getItem("companyColor") || "#FFFFFF";
    this.themeColor = color;
    this.textColor = this.getTextColor(this.themeColor);
    this.disabledButtonColor = this.getDisabledColor(this.themeColor);
    this.hoveredButtonColor = this.getHoverColor(this.themeColor);
    this.iconColor = this.getIconBgColor(this.themeColor);
    this.disabledButtonTextColor = this.getTextColor(this.disabledButtonColor);
    this.buttonBgColor = this.getButtonBgColor(this.themeColor);


    document.documentElement.style.setProperty("--theme-color", this.themeColor);
    document.documentElement.style.setProperty("--text-color", this.textColor);
    document.documentElement.style.setProperty("--disabled-button-color", this.disabledButtonColor);
    document.documentElement.style.setProperty("--hovered-button-color", this.hoveredButtonColor);
    document.documentElement.style.setProperty("--icon-color", this.iconColor);
    document.documentElement.style.setProperty("--disabled_button-text-color", this.disabledButtonTextColor);
    document.documentElement.style.setProperty("--button-bg-color",this.buttonBgColor);
  }

  public getTextColor(hex: string): string {
    if (!hex || hex.length < 7 || hex[0] !== "#") {
      console.warn("Invalid hex color provided:", hex);
      return "black";
    }

    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 0.3 ? "#000000" : "#ffffff";
  }

  public getHoverColor(hex: string): string {
    return this.adjustBrightness(hex, 20); 
  }

  public getDisabledColor(hex: string): string {
    return this.adjustBrightness(hex, -40); 
  }

  public getIconBgColor(hex: string): string {
    return this.adjustBrightness(hex,-20);
  }

  public getButtonBgColor(hex : string) : string {
    return this.adjustBrightness(hex, 40);
  }

  private adjustBrightness(hex: string, percent: number): string {
    const r = Math.min(255, Math.max(0, parseInt(hex.slice(1, 3), 16) + (255 * percent / 100)));
    const g = Math.min(255, Math.max(0, parseInt(hex.slice(3, 5), 16) + (255 * percent / 100)));
    const b = Math.min(255, Math.max(0, parseInt(hex.slice(5, 7), 16) + (255 * percent / 100)));
    
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }
}