<div class="relative flex flex-col items-center justify-center p-4 rounded-lg shadow-lg transition-transform duration-500 border border-primaryBlack/20
            group hover:shadow-xl cursor-pointer w-full max-w-[280px] sm:max-w-[200px] md:max-w-[220px] lg:max-w-[250px] xl:max-w-[300px]">
    <button (click)="deleteEmployeeFromProject($event)"
            class="absolute top-2 right-2 flex items-center gap-1 opacity-40 hover:opacity-100 transition-opacity duration-500">
        <img src="../../../assets/icons/TrashIcon.png" class="w-4 h-4"/>
        <p class="text-xs font-light text-gray-600">Remove</p>
    </button>
    <div class="relative w-24 h-24 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 xl:w-32 xl:h-32 rounded-full overflow-hidden border border-primaryBlack/20 mb-3">
        <img [src]="employeePictureLink" alt="Employee Picture" class="w-full h-full object-cover"/>
    </div>
    <div class="text-center">
        <p class="font-medium text-primaryBlack text-base sm:text-sm md:text-lg lg:text-xl xl:text-2xl">{{ fullName }}</p>
        <span class="text-xs sm:text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wide">{{ role }}</span>
    </div>
</div>