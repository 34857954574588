import { Routes } from '@angular/router';
import { MagicLinkLoginComponent } from './pages/magic-link-login/magic-link-login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { VacanciesComponent } from './pages/vacancies/vacancies.component';
import { EmployeeAssetsComponent } from './pages/employee-assets/employee-assets.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { EngagementsComponent } from './pages/engagements/engagements.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';

export const routes: Routes = [
    {path: ":domain", component: MagicLinkLoginComponent},
    {path: ":domain/profile/:email/:code", component: ProfileComponent},
    {path: ":domain/notifications/:email/:code/:uuid", component: NotificationsComponent},
    {path: ":domain/vacancies/:email/:code", component: VacanciesComponent},
    {path: ":domain/employeeAssets/:email/:code", component:EmployeeAssetsComponent},
    {path: ":domain/finance/:email/:code", component:FinanceComponent},
    {path: ":domain/engagements/:email/:code", component: EngagementsComponent},
    {path: ":domain/activities/:email/:code", component: ActivitiesComponent},
    {path: ":domain/projects/:email/:code", component: ProjectsComponent},
    {path: ":domain/projects/:id/:email/:code", component: ProjectDetailsComponent},
    {path: "auth/:domain/:email/:code/:uuid", redirectTo: ":domain/notifications/:email/:code/:uuid", pathMatch: 'full'},
];
