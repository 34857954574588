import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TasksService } from '../../services/tasks.service';
import { NgToastService } from 'ng-angular-popup';
import { Task } from '../../models/task';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../../services/company.service';
import { TaskCardComponent } from '../../components/task-card/task-card.component';

@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [CommonModule, DragDropModule, TaskCardComponent],
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.css'
})
export class TasksComponent {
  public tasks: Task[] = [];
  public todoTasks: Task[] = [];
  public inProgressTasks: Task[] = [];
  public doneTasks: Task[] = [];
  public activeSection: string | null = null;
  public companyId : string = '';

  constructor(
    private modalService: NgbModal,
    private taskService: TasksService,
    private toastService: NgToastService,
    private companyService : CompanyService
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  public getAll(): void {
    this.companyService.getIdByDomain().subscribe(
      (response : string) => {
        this.companyId = response;
        this.taskService.getMyTasks(response).subscribe(
          (response: Task[]) => {
            console.log("MY TASKS: ", response);
            this.tasks = response;
            this.filterTasksByStatus();
          }
        );
      }
    )
  }

  private filterTasksByStatus(): void {
    this.todoTasks = this.tasks.filter(task => task.Status === 'TO DO');
    this.inProgressTasks = this.tasks.filter(task => task.Status === 'IN PROGRESS');
    this.doneTasks = this.tasks.filter(task => task.Status === 'DONE');
  }

  // public addNewaTask(): void {
  //   const modalRef = this.modalService.open(AddNewTaskModalComponent, {
  //     backdrop: 'static',
  //     keyboard: true,
  //   });

  //   modalRef.componentInstance.project = null;

  //   modalRef.componentInstance.taskCreated.subscribe(() => {
  //     this.toastService.success('Task created successfully!', 'Success!', 3000);
  //     this.getAll();
  //   });
  // }

  public drop(event: CdkDragDrop<Task[]>, newStatus: string): void {
    const task: Task | undefined = event.item.data;

    if (task) {
      task.Status = newStatus;
      this.taskService.updateStatus(task.ID, newStatus, this.companyId).subscribe(() => {
        this.toastService.success('Task status updated successfully!', 'Success!', 3000);
        this.getAll();
        setTimeout(() => {
          window.location.reload();
        }, 0);
      });
    } else {
      console.error('Dragged item data is undefined');
    }

    this.activeSection = null;
  }

  public onDragEntered(section: string): void {
      this.activeSection = section;
  }

  public onDragExited(section: string): void {
    if (this.activeSection === section) {
      this.activeSection = null;
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public handleTaskDeleted() : void {
    this.toastService.success("Task successfully deleted!", "Success!", 3000);
    this.getAll();
    window.location.reload();
  }

  public handleTaskEdited() : void {
    this.toastService.success("Task successfully edited!", "Success!", 3000);
    this.getAll();
  }

}
