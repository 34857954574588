import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { BasicCompanyDTO } from '../../models/basicCompanyDTO';
import { CustomThemeService } from '../../services/custom-theme.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.css'
})
export class NotificationsComponent implements OnInit{
  public token : string = ''

  constructor(private route: ActivatedRoute,
    private authService : AuthService,
    private companyService : CompanyService,
    private themeService : CustomThemeService
  ){}

  ngOnInit(): void {
    this.themeService.applyThemeColor();
    this.prepareData();
  }

  public prepareData() : void {
    const domainFromRoute = this.route.snapshot.paramMap.get('domain');
    if(domainFromRoute != null){
      sessionStorage.setItem('domain', domainFromRoute)
    }

    const uuidFromRoute = this.route.snapshot.paramMap.get('uuid');
    const emailFromRoute = this.route.snapshot.paramMap.get('email');
    const codeFromRoute = this.route.snapshot.paramMap.get('code');
    const token = sessionStorage.getItem('token')
  
    if(emailFromRoute !== null && codeFromRoute !== null && domainFromRoute !== null && uuidFromRoute !== null && token === null){
      this.authService.getToken(domainFromRoute, emailFromRoute, codeFromRoute, uuidFromRoute).subscribe(
        (response : HttpResponse<any>) => {
          const token = response.headers.get('accesstoken');
          if(token){
            console.log("NOVA TOKENCINA 7: ", token);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('code', codeFromRoute);
          
            this.authService.getUser().subscribe(
              (response : any) => {
                console.log("User response: ", response);
                sessionStorage.setItem('employeeId', response.EmployeeID)
              }
            )
            this.authService.authStatusSubject.next(true);
            this.getBaseCompanyDetails();
          }
        }
      )
    }
  }

  public getBaseCompanyDetails() : void {
    const domain = sessionStorage.getItem('domain')
    if(domain){
      this.companyService.getBaseCompanyDetails(domain).subscribe(
        (response : BasicCompanyDTO) => {
          sessionStorage.setItem('companyColor', response.colorHash);
          sessionStorage.setItem('companyName', response.name);
          sessionStorage.setItem('companyLogo' , response.logo);
          this.companyService.companyStatusSubject.next(true);
          this.companyService.companyColorSubject.next(true);
          console.log(response.colorHash);
        }
      )
    }
  }
}
