<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Activities</p> 
    </div> 
    <div class="flex flex-row w-full items-center bg-lightGreen/20 rounded-md p-4 gap-4">
        <img src="../../../assets/icons/SupportIcon.png" class="size-[20px]"/>
        <div class="flex flex-col w-full">
            <p>This list shows your previous activities. The current activity will be added to this list only when you mark it as complete. For activity details, open the details of the desired activity.</p>
        </div>
    </div>
    <table class="w-full text-sm md:text-md lg:text-lg xl:text-lg text-left rtl:text-right border-[1px] border-primaryBlack/20">
        <thead class="text-xs sm:text-sm md:text-md xl:text-md text-primaryBlack uppercase bg-gray-50">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Title
                </th>
                <th scope="col" class="px-6 py-3">
                    Project
                </th>
                <th scope="col" class="px-6 py-3">
                    Start
                </th>
                <th scope="col" class="px-6 py-3">
                    Duration
                </th>
                <th scope="col" class="px-6 py-3">
                    End
                </th>
                <th scope="col" class="px-6 py-3">
                    Details
                </th>
            </tr>
        </thead>
        <tbody>
            @for (activity of fileterdActivities; track $index) {
                <tr class="bg-primaryWhite h-[45px] border-b items-center transition-[0.5s]">
                    <td scope="row" class="flex items-center px-6 py-4 text-gray-900  whitespace-nowrap dark:text-white gap-4 img-container">
                        <div class="font-semibold">
                            <p class="font-medium text-[18px] text-primaryBlack">{{activity.Title}}</p>
                        </div>
                    </td>
                    <td class="px-6">
                        <p class="font-medium text-[18px] text-primaryBlack">{{activity.project.ProjectName}}</p>
                    </td>
                    <td class="px-6">
                        <p class="font-medium text-[18px] text-primaryBlack">{{activity.startDateParsed}}</p>
                    </td>
                    <td class="px-6">
                        <p class="font-medium text-[18px] text-primaryBlack">8 hours</p>
                    </td>
                    <td class="px-6">
                        <p class="font-medium text-[18px] text-primaryBlack">{{activity.endDateParsed}}</p>
                    </td>
                    <td class="px-6 gap-2" (click)="viewDetails(activity)">
                        <p class="cursor-pointer text-primaryBlack underline font-medium text-[16px] hover:text-lightGreen transition-[0.5s]">View</p>
                    </td>
                </tr>
            }
        </tbody>
    </table>
</div>