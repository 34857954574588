<div class="flex flex-col w-full p-4 gap-2 rounded-md border-[1px] border-primaryBlack/20 bg-primaryWhite
            hover:translate-y-[-5px] hover:shadow-md hover:border-lightGreen transition-[0.5s] relative">
    <div [ngClass]="{'bg-paused/40' : task?.Priority === 'Top',
                    'bg-finished/40' : task?.Priority === 'Low',
                    'bg-active/40' : task?.Priority === 'Mid'}" 
        class="flex p-2 w-[100px] rounded-md">
        <p  [ngClass]="{'text-primaryBlack' : task?.Priority === 'Top',
                        'text-lightGreen' : task?.Priority === 'Low',
                        'text-[#0044CC]' : task?.Priority === 'Mid'}" 
            class="text-[12px] font-medium uppercase">{{task?.Priority}} Priority</p>
    </div>
      
    <div [id]="'dropdown' + task?.ID" class="z-[100] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" [attr.aria-labelledby]="'dropdownButton' + task?.ID">
            <li (click)="editTask()" class="cursor-pointer">
                <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</p>
            </li>
            <li (click)="deleteTask()" class="cursor-pointer">
                <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</p>
            </li>
        </ul>
    </div>
    <p class="text-primaryBlack text-[14px]">
        {{task?.Description}}
    </p>
    <hr class="w-full text-primaryBlack/50">  
    <div class="flex flex-row w-full items-center justify-between">
        <div class="flex w-[80%] justify-start">
            <p class="cursor-pointer">{{project?.Name}}</p>
        </div>
    </div> 
</div>