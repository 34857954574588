import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeamsService } from '../../services/teams.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-team-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-card.component.html',
  styleUrl: './team-card.component.css'
})
export class TeamCardComponent implements OnInit {

  @Input() teamId: string = "";
  @Output() teamDeleted = new EventEmitter<any>();

  private projectId: string = "";
  public team: any;

  constructor(
    public navigationService: NavigationService,
    private teamsService: TeamsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toast : NgToastService
  ) { }

  public ngOnInit(): void {
    const currentRoute = this.route.snapshot.paramMap.get('id');
    if (currentRoute !== null) {
      this.projectId = currentRoute;
    }
    this.teamsService.getTeamById(this.teamId).subscribe(
      (response: any) => {
        //console.log("This is the getById team response:", response);
        this.team = response;
      },
      (error: HttpErrorResponse) => {
        //console.log("Error has occured while trying to get teams ", error);
      }
    );
  }

  public deleteTeamFromProject(event: any): void {
    event.stopPropagation();

    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop: 'static', keyboard: true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        if (this.teamId && this.projectId) {

          this.teamsService.removeFromProject(this.projectId, this.teamId).subscribe(
            (response : any) => {
              this.teamDeleted.emit();
              this.toast.success("Team removed from project.");
            }
          );
        }
      }
    );
  }

}
