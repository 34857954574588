<div class="flex w-full h-full bg-primaryBlack/40 items-center justify-center absolute top-0 left-0">
    <div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Add team to project</p>
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-red transition-[0.5s]" (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>
            </div>
            <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
                <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">Teams</p>
                <p class="text-primaryBlack font-light text-[12px] mb-2">Select teams that you want to add to the
                    project</p>
                <div class="flex flex-row w-full flex-wrap justify-evenly items-center max-h-[300px] overflow-y-auto">
                    @for (team of teams; track $index) {
                    @if (team.Name !== "Bench") {
                    <div class="flex flex-row min-w-[300px] max-w-auto p-4 border-[1px] rounded-md gap-4 relative
                                    hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                        [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(team.ID),
                                    'border-[1px]':!isActive(team.ID)}" (click)="setActive(team)">
                        <div class="flex flex-col">
                            <p class="font-medium text-[14px] text-primaryBlack">{{team.Name}}</p>
                            <span [ngStyle]="{'background' : getBadgeBackgroundColor(team), 'color' : team?.ColorHash}"
                                class="text-sm w-[] font-medium px-2.5 py-0.5 rounded">{{team?.Name}}</span>
                        </div>

                        <div class="flex flex-row items-center absolute bottom-4 right-4 gap-2">
                            <p class="text-[14px] font-semibold">{{team.Employees.length}}</p>
                            @if (team.Employees.length === 1) {
                            <img src="../../../assets/icons/UserIcon.png" class="size-[15px]" />
                            }
                            @else if (team.Employees.length === 2 || team.Employees.length === 3) {
                            <img src="../../../assets/icons/EmployeesIcon.png" class="size-[18px]" />
                            }
                            @else {
                            <div class="flex relative">
                                <img src="../../../assets/icons/EmployeesIcon.png" class="size-[18px]" />
                                <p class="absolute top-[-8px] right-0 text-primaryBlack font-medium text-[12px]">+</p>
                            </div>
                            }
                        </div>
                    </div>
                    }
                    }
                </div>
            </div>

            <div class="flex flex-row w-full justify-end">
                <button
                    class="flex h-[45px] rent-asset-button font-medium font-poppins rounded-md relative text-[18px] transition-[0.5s] items-center justify-center p-4 gap-2 border-slate-50"
                    (click)="addTeamToProject()">
                    <div class="flex justify-end items-center ">Add team to project</div>
                </button>
            </div>
        </div>
    </div>
</div>