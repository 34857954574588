import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from '../../../services/activity.service';
import { ActivityLog } from '../../../models/activityLog';

@Component({
  selector: 'app-activity-details',
  standalone: true,
  imports: [],
  templateUrl: './activity-details.component.html',
  styleUrl: './activity-details.component.css'
})
export class ActivityDetailsComponent implements OnInit{

  @Input() activity? : any;

  public breaks : any[] = [];

  constructor(public modalService : NgbActiveModal, 
    private activityService : ActivityService
  ){}

  ngOnInit(): void {
    console.log(this.activity);
    this.parseBreaks();
  }

  

  public parseBreaks() : void {
    const breaks = this.activity?.Breaks;
    
    if(breaks) {
      breaks.forEach((el: { Start: string | number | Date; Duration: any; }) => {
        const date = new Date(el.Start);
        const parsedDate = date.toISOString().substring(0, 19);
  
        const breakParsed = {
          parsedStartDate : parsedDate.split('T')[0] + ' ' + parsedDate.split('T')[1],
          duration : el.Duration
        };
  
        this.breaks.push(breakParsed);
      });
    }

    console.log("BREAKS: ", this.breaks);
  }
}
