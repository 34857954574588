import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailableAsset } from '../../../models/availableAsset';
import { CommonModule, DatePipe } from '@angular/common';
import { EmployeeService } from '../../../services/employee.service';
import { ButtonComponent } from '../../button/button.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Engagement } from '../../../models/engagement';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgToastModule, NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-rent-asset-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, FormsModule, ReactiveFormsModule, DatePipe],
  templateUrl: './rent-asset-modal.component.html',
  styleUrl: './rent-asset-modal.component.css'
})
export class RentAssetModalComponent implements OnInit{

  @Input() selectedAsset! : AvailableAsset;
  @Input() engagements : Engagement[] = [];

  @Output() assetRented = new EventEmitter<void>();

  public activeProjectId : string = '';
  public endDate : string = '';

  public bookCount : number = 1;
  public bookControl = new FormControl("");

  public hasPicture : boolean = false;
  public assetPictureLink : any;

  public hasCompanyColor : boolean = false;
  public companyColor : any;

  public minDate: string;

  constructor(public modalService : NgbActiveModal,
    public employeeService : EmployeeService,
    private assetService : AssetsService,
    private toast : NgToastService
  ){
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;
  }

  ngOnInit(): void {
    this.getEngangementsForEmployee();
    //this.bookControl = new FormControl("",[Validators.min(1),Validators.max(10)]);
    console.log("This is the number of assets: ", this.selectedAsset);
  }

  public getEngangementsForEmployee() {
    this.assetService.getEngagementsForEmployee().subscribe(
      (response : any) => {
        //console.log("Ovo je response: ", response);
        this.engagements = response;
      },
      (error : HttpErrorResponse) => {
        console.log("Error loading engangments for employee: ", error);
      }
    );

  }

  public getEngagements() {
    const employeeID = sessionStorage.getItem("employeeId")
    if(employeeID){
      this.employeeService.getEngagements(employeeID).subscribe(
        (response : Engagement[]) => {
          this.engagements = response;
          //console.log(this.engagements);
        }
      )
    }
  }

  public bookAsset() {

    const endDate = new Date(this.endDate);
    const endDateMillis = endDate.getTime();

    const body = {
      // projectID : this.activeProjectId,
      projectID : this.activeProjectId,
      endTime : endDateMillis,
      counter : this.bookCount
    }

    console.log(body);

    this.assetService.rentAsset(this.selectedAsset.ID, body).subscribe(
      (response : any) => {
        //console.log("Asset rented successfully!", response);
        this.modalService.close();
        this.assetRented.emit();
      },
      (error : HttpErrorResponse)  => {
        console.error("Here is the full error object: ", error);
        if (error.error === "You already sent request for this asset") {
          this.toast.danger("Booking request has already been sent.");
        } else {
          this.toast.danger("An error has occured while trying to book an asset.");
          console.log("An error has occured while trying to book an asset: ", error);
        }
      }
    )
  }

    // public getAssetImage(asset : any): void {
  //   const company = sessionStorage.getItem("company");
  //   let param;
  //   let companyId;

  //   if (asset.defaultPicture) {
  //     param = "defaultasset";
  //     companyId = "firmegeneralepictures";
  //   } else {
  //     param = asset.ID;
  //     companyId = company;
  //   }

  //   this.assetService.getAssetImage(param, companyId).subscribe(
  //     (response: string) => {
  //       this.assetPictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.log("Error while getting S3 image link, ", error.message);
  //     }
  //   );
  // }

  public setActive(projectID: string): void {
    console.log("Project: ", projectID);
    console.log("Date ", this.endDate);
    this.activeProjectId = projectID;
  }

  public isActive(projectID : string): boolean {
    return this.activeProjectId === projectID;
  }


}
