import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { NgToastService } from 'ng-angular-popup';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { DomSanitizer } from '@angular/platform-browser';
import { ColorTranslator } from 'colortranslator';
import { ButtonComponent } from '../../components/button/button.component';
import { AuthService } from '../../services/auth.service';
import { BasicCompanyDTO } from '../../models/basicCompanyDTO';
import { CompanyService } from '../../services/company.service';
import { CustomThemeService } from '../../services/custom-theme.service';

@Component({
  selector: 'app-magic-link-login',
  standalone: true,
  imports: [FormsModule, ButtonComponent, CommonModule, ReactiveFormsModule],
  templateUrl: './magic-link-login.component.html',
  styleUrl: './magic-link-login.component.css'
})
export class MagicLinkLoginComponent implements OnInit{

  form!: FormGroup;

  public isLoading : boolean = false;

  public email : string = '';
  public domain : string = '';
  public linkSent : boolean = false;
  public lightText : boolean = false;
  public isDataLoaded : boolean = false;

  public basicCompanyData? : BasicCompanyDTO;
  public imageLink : any;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    // private toast : NgToastService,
    private fb : FormBuilder,
    private sanitizr : DomSanitizer,
    private companyService : CompanyService,
    private customTheme : CustomThemeService,
    @Inject(ChangeDetectorRef) private cdRef: ChangeDetectorRef){}

  ngOnInit(): void {
    const domainFromRoute = this.route.snapshot.paramMap.get('domain');
    if(domainFromRoute != null){
      this.domain = domainFromRoute;
      console.log(this.domain);
    }

    this.getBaseCompanyDetails();

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      // uuid: ['', Validators.required],
    });
  }

  public sendMagicLink(): void {
    if(this.form.valid){
      const formValue = this.form.value;
      const uuid = this.generateDeviceUUID();
      console.log("NOVI UUID: ", uuid);
      localStorage.removeItem('deviceId');
      localStorage.setItem('deviceId', uuid);

      const body = {
        email: formValue.email,
      };

      this.isLoading = true;
      this.authService.magicLinkLogin(this.domain, body, uuid).subscribe(
        (response: string) => {
          this.linkSent = true;
          console.log(response);
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          console.error("Error while sending magic link to entered email", error.message);
          this.isLoading = false;
        }
      );
    }
  }

  public generateDeviceUUID() : string {
    const s : any[] = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }

  public getDeviceId() : string{
    let deviceId = localStorage.getItem('deviceId')
    if(!deviceId) {
       deviceId = this.generateDeviceUUID();
       localStorage.setItem('deviceId', deviceId);
    }
    return deviceId
  }

  public getBaseCompanyDetails() : void {
    this.companyService.getBaseCompanyDetails(this.domain).subscribe(
      (response : BasicCompanyDTO) => {
        this.basicCompanyData = response;
        console.log(this.basicCompanyData.colorHash);
        sessionStorage.setItem('companyColor', this.basicCompanyData.colorHash);
        this.customTheme.applyThemeColor();
        this.getColors(this.basicCompanyData.colorHash)
        this.imageLink = this.sanitizr.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.basicCompanyData.logo);
      }
    )
  }

  public getColors(companyColor : string){
    if(companyColor){
      const color_hsl = new ColorTranslator(companyColor);
      console.log("HSL Color L: ", color_hsl.L)
      
      if(color_hsl.L >= 50) {
        this.lightText = false;
      } else {
        this.lightText = true;
      }
      console.log("SETOVANO NA: ", this.lightText);
      this.isDataLoaded = true;
      this.cdRef.detectChanges();
    }
  }
}
