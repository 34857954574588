<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Engagements</p> 
    </div> 
    <div class="flex justify-between items-end w-full flex-row md:flex-row space-y-4 md:space-y-0">
        <div class="flex flex-col border-[1px] rounded-md p-2 gap-2 justify-start items-start">
            <div class="flex flex-row items-center gap-2">
                <img src="../../../../assets/icons/FilterIcon.png" class="size-[15px]"/>
                <p class="text-primaryBlack text-[14px]">Filters</p>
            </div>
            <div class="flex flex-row gap-4 items-start">
                <p class="text-primaryBlack font-light text-[12px]">Project status</p>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterActive" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">ACTIVE</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterFinished" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">FINISHED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterPaused" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">PAUSED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterScheduled" (change)="filterEngagements()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">SCHEDULED</p>
                </div>
            </div>
        </div>
        <div class="flex flex-row gap-4 items-center">
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-lightBlue rounded-full me-1.5 flex-shrink-0"></span>Active</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-scheduled rounded-full me-1.5 flex-shrink-0"></span>Scheduled</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-lightYellow rounded-full me-1.5 flex-shrink-0"></span>Paused</span>
            <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-lightGreen rounded-full me-1.5 flex-shrink-0"></span>Finished</span>
        </div>
    </div>
    <div class="flex flex-row items-center jusitfy-evenly">
        @for (engagement of filteredEngagements; track $index) {
            <app-engagement-card
                [engagement]="engagement"
            />
        }
    </div>
</div>