import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Form, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from '../../../services/activity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityLog } from '../../../models/activityLog';

@Component({
  selector: 'app-end-activity-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './end-activity-modal.component.html',
  styleUrl: './end-activity-modal.component.css'
})
export class EndActivityModalComponent implements OnInit{

  @Output() activityEnded = new EventEmitter<void>();

  public form! : FormGroup;
  public activeLogStartDate : string = '';

  public activeLog? : ActivityLog;

  constructor(public modalService : NgbActiveModal,
    private fb : FormBuilder,
    private activityService : ActivityService
  ){}

  ngOnInit(): void {
    this.initializeForm();
    this.getActiveLog();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      'Description' : ['', Validators.required],
      'Duration' : [0, [Validators.required, Validators.min(0)]]
    });
  }

  public getActiveLog() : void {
    this.activityService.getActiveLog().subscribe(
      (response : ActivityLog) => {
        this.activeLog = response;
        console.log("ACTIVE LOG: ", this.activeLog);
        
        const date = new Date(this.activeLog.Start);
        const startDateAndTime = date.toISOString().split('T');
        const startTimeParsed = startDateAndTime[1].slice(0, 11);

        this.activeLogStartDate = startDateAndTime[0] + ' ' + startTimeParsed
      }
    );
  }

  public endActivity() : void {
    if(this.form.valid && this.activeLog) {
      const formValue = this.form.value;
      
      console.log("Active log ID: ", this.activeLog.ID);
      console.log("Duration: ", formValue.Duration);
      console.log("Description: ", formValue.Description);

      const startDate = new Date(this.activeLog.Start);
      const endDate = new Date();

      const timeDifferenceMillis = endDate.getTime() - startDate.getTime();
      console.log("Difference in milliseconds: ", timeDifferenceMillis);

      console.log("Entered duration: ", formValue.Duration);
      const enteredDurationInMillis = formValue.Duration * 1000 * 3600;
      console.log("Entered duration in milliseconds: ", enteredDurationInMillis);

      let duration;

      formValue.Duration !== 0 ? duration = formValue.Duration : duration = timeDifferenceMillis;

      this.activityService.endActivity(this.activeLog?.ID, duration, formValue.Description).subscribe(
        (response : any) => {
          console.log(response);
          this.activityEnded.emit();
          this.modalService.close();
        }
      );
    }
  }
}
