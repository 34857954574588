<div class="fixed inset-0 bg-primaryBlack/40 flex items-center justify-center z-50">
    <div class="bg-primaryBlack/30 flex items-center justify-center w-full h-full font-poppins">
      <div class="flex flex-col bg-white w-[400px] max-w-full h-auto items-center justify-center rounded-md p-6 gap-6 shadow-lg">
        
        <div class="flex flex-row w-full items-center justify-end">
          <button class="flex w-[30px] h-[30px] rounded-md bg-darkGreen items-center justify-center 
                          hover:bg-red transition-[0.5s] outline-none" 
                  (click)="closeModal()">
            <img src="../../../assets/icons/CloseIcon.png" class="w-[20px] h-[20px] nav_menu_icon">
          </button>        
        </div>
        
        <div class="flex flex-col items-center justify-center w-full gap-4">
          <img src="../../../../assets/icons/WarningIcon.png" class="w-[70px] h-[70px]"/>
        </div>
        
        <div class="flex flex-col w-full items-center justify-center text-center">
          <p class="text-[20px] font-medium text-lightRed">Warning!</p>
          <p class="text-[16px] text-primaryBlack">The action you want to take will be irreversible.</p>
          <p class="text-[16px] text-primaryBlack">Are you sure you want to perform this action?</p>
        </div>
        
        <div class="flex flex-row gap-4 w-full items-center justify-center">
          <button class="px-4 h-[45px] items-center justify-center rounded-md bg-lightRed text-primaryWhite font-medium 
                          text-[14px] transition-[0.5s] hover:bg-darkRed"
                  (click)="performAction()">
            Yes, I'm sure
          </button>
          <button class="px-4 h-[45px] items-center justify-center rounded-md border-[1px] transition-[0.5s]
                          text-primaryWhite bg-primaryBlack/70 font-medium text-[14px] hover:bg-primaryBlack/40"
                  (click)="closeModal()">
            No, cancel
          </button>
        </div>
      </div>
    </div>
  </div>