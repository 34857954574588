import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'app-equipment-history-card',
  standalone: true,
  imports: [],
  templateUrl: './equipment-history-card.component.html',
  styleUrl: './equipment-history-card.component.css'
})
export class EquipmentHistoryCardComponent implements OnInit{
  @Input() function! : () => void;
  @Input() startDate : number = 0;
  @Input() endDate : number = 0;
  @Input() assetId : string = "";
  @Input() name : string = "";
  
  public hasPicture : boolean = false;
  public imageUrl : any;

  constructor(
    private sanitizer : DomSanitizer,
    private assetService : AssetsService
  ){}

  ngOnInit(): void {
    this.getAssetImage();
  }


  public getAssetImage() : void {
    this.assetService.getAssetImage(this.assetId).subscribe(
      (response : string) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
        this.hasPicture = true;
      }
    );
  }

  public getDate(date: number): string {
    const startDate = new Date(date);
    const startDateParsed = startDate.toISOString().substring(0, 10);
    return startDateParsed;
  }

}
