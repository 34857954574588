<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Finance</p> 
    </div>
    
    <div class="flex flex-row w-full items-center gap-4">
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-between justify-center">
                <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">NET SALARY</p>
            </div>
            <div class="flex flex-col w-full items-start justify-center gap-2">
                <p class="text-[32px] font-bold text-lightGreen">${{netSalary}}</p>
                <div class="flex flex-row items-center w-full justify-between justify-center">
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{netPerHour}}</span> /hour
                    </p>
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{netPerDay}}</span> /day
                    </p>
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{netPerYear}}</span> /year
                    </p>
                </div>
            </div>
        </div>
    
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-between justify-center">
                <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">GROSS SALARY</p>
            </div>    
            <div class="flex flex-col w-full items-start justify-center gap-2">
                <p class="text-[32px] font-bold text-lightGreen">${{grossSalary}}</p>
                <div class="flex flex-row items-center w-full justify-between justify-center">
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{grossPerHour}}</span> /hour
                    </p>
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{grossPerDay}}</span> /day
                    </p>
                    <p class="text-[12px] font-light text-primaryBlack mt-2">
                        <span class="text-lightGreen font-bold">${{grossPerYear}}</span> /year
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">SALARY HISTORY</p>
        <div id="chart" ></div>
    </div>
</div>