import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
// import { NgToastService } from 'ng-angular-popup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-info-card',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './user-info-card.component.html',
  styleUrl: './user-info-card.component.css'
})
export class UserInfoCardComponent implements OnInit{
  @Input() firstName? : string = '';
  @Input() lastName? : string = '';
  @Input() email? : string = '';
  @Input() emergencyContact? : string = '';
  @Input() dateOfBirth : string = '';
  @Input() imagePath : any;
  @Input() bio? : any;
  @Input() employeeId: string = '';
  @Input() atEmployeeProfilePage : boolean = false;
  @Input() role : string = '';
  @Input() defaultPicture : boolean = true;
  @Input() employeeProfile : boolean = false;

  @Output() bioEdited = new EventEmitter<string>();
  @Output() imageChanged = new EventEmitter<string>();

  public editingBio : boolean = false;
  public tmpBio : string = "";

  public editingPersonal : boolean = false;
  public dotedOpened : boolean = false;

  dateToDisplay : string = ''

  public hasPicture : boolean = true;
  public employeePictureLink : any;
  
  constructor(private employeeService : EmployeeService,
    // private toast : NgToastService,
    private modalService : NgbModal,
    private routes : ActivatedRoute,
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void { 
    this.getEmployeePicture();
    if(this.dateOfBirth){
      const date = new Date(this.dateOfBirth);
      this.dateToDisplay = date.toISOString().substring(0,10)
    }  
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem("company");
    let param;
    let companyId;
    if(this.defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures"
    } else {
      param = this.employeeId
      companyId = company;
    }

    if(!this.employeeProfile){
      this.employeeService.getEmployeeImage(param, companyId).subscribe(
        (response : string) => {
          this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);;
          this.hasPicture = true;
        },
        (error : HttpErrorResponse) => {
          this.hasPicture = false;
        }
      )
    } else {
      this.employeeService.getUserProfilePicutre().subscribe(
        (response : string) => {
          this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);;
          this.hasPicture = true;
        },
        (error : HttpErrorResponse) => {
          this.hasPicture = false;
        }
      )
    }
  }
}
